import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import LoginComponent from "../components/LoginComponent";
import { loginService } from "services/user"

const Login = (props) => {
    const { updateUser, history, user } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async (values) => {
        setLoading(true);

        const data = {
            email: values.email,
            password: values.password
        }

        try {
            const response = await loginService(data);
            updateUser(response);

            switch (response.role.toLowerCase()) {
                case "club":
                    history.push(`/club/${response.id}/booking/general-entrance`)
                    break;
                case "Admin":
                    history.push(`/admin/${response.id}/my-club`)
                    break;

                default:
                    break;
            }
            history.push(``);
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    return (<LoginComponent
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        history={history}
    />);
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Login));