import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import { Link } from "react-router-dom";
import './LoginComponent.scss';

const LoginComponent = (props) => {
    const { loading, submit, error } = props;

    return (
        <AuthLayout>
            <div className="login-component">
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={yup.object().shape({
                        email: yup
                            .string()
                            .email("E-mail invalido")
                            .required("Campo obligatorio"),
                        password: yup
                            .string()
                            .required("Campo obligatorio"),
                    })}
                    onSubmit={(values) => submit(values)}
                >
                    {(formik) => (
                        <>
                            <Form>
                                <Input
                                    id="email"
                                    formik={formik}
                                    type="text"
                                    label="Email"
                                    placeholder="Email"
                                />
                                <Input
                                    id="password"
                                    formik={formik}
                                    type="password"
                                    label="Contraseña"
                                    className="formControl"
                                    placeholder="Contraseña"
                                />

                                <Link to="/recovery-password" className="forgot-password">¿Olvidaste tu contraseña?</Link>

                                {
                                    error ?
                                        <div className="error-message-global">Credenciales incorrectas.</div> :
                                        null
                                }

                                <Button
                                    loading={loading}
                                    type="submit"
                                    onClick={formik.handleSubmit}>
                                    Iniciar sesión

                                </Button>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </AuthLayout>
    );
}

export default LoginComponent;
