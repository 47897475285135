import React, { useState, useEffect } from 'react'
import ProfileComponent from '../components/ProfileComponent'
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';
import { getClubInfoservice, patchClubService } from 'services/clubs';

const ProfileContainer = (props) => {
    const { history, user, updateUser } = props;

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        try {
            const res = await getClubInfoservice(user.id);
            updateUser({ ...user, ...res });
        }
        catch (e) {
            console.log('error setting preferences', e);
        }

    }

    const submit = async (values) => {
        let data = {
            name: values.name,
            description: values.description,
            location: {
                zone: values.zone,
                address: values.direction,
                city: values.city,
                postalCode: values.zipCode,
                latitude: values.latitude,
                longitude: values.longitude,
            },
            maxPeople: values.maxPeople,
        };

        if (values.image?.base64) data.image = values.image?.base64;
        if (user.firstLogin) data.firstLogin = false;

        try {
            setLoading(true);
            await patchClubService(user.id, data);

            await getUser();

            toast(<ToastrContent type="success" title={"Éxito"} message={"Tu perfil se ha cambiado."} />, {
                progressClassName: "toastr-progress-bar success",
            });


            history.push(`/club/${user.id}/my-club/my-profile`);
        }
        catch (e) {
            console.log('error ', e);

            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde"} />, {
                progressClassName: "toastr-progress-bar danger",
            });
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <ProfileComponent
            {...props}
            {...{ submit, loading, user, history }}
        />
    )
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileContainer))