import React, { useCallback, useEffect, useState } from "react";
import { deleteClubService, getClubsInfoservice, patchClubService, postClubRegister, suspentClubService } from "services/clubs";
import MyClubAdminComponent from "../components/MyClubAdminComponent";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';

const MyClubAdmin = (props) => {
    const { history, user } = props;
    const [loading, setLoading] = useState(false);
    const [clubs, setClubs] = useState();

    const getClubs = useCallback(async () => {
        try {
            const res = await getClubsInfoservice();
            if (res) {
                setClubs(res);
            }

        } catch (e) {
            console.log('error ', e);
        }
    }, [])

    useEffect(() => {
        getClubs();
    }, [getClubs])

    function showFailRequest() {
        return (
            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde"} />, {
                progressClassName: "toastr-progress-bar danger",
            })
        )
    }

    const submit = async (values) => {
        try {
            setLoading(true);

            const objectValues = {
                email: values.email,
                name: values.name,
                image: values.image.base64,
                description: values.description,
                location: {
                    zone: values.zone,
                    address: values.direction,
                    city: values.city,
                    postalCode: values.zipCode,
                    latitude: values.latitude,
                    longitude: values.longitude,
                },
                maxPeople: Number(values.maxPeople),
            }
            const res = await postClubRegister(objectValues);

            if (res) {
                setLoading(false);
                // history.push('/admin/${user.id}/my-club');
            }
            toast(<ToastrContent type="success" title={"Éxito"} message={"El perfil fue creado."} />, {
                progressClassName: "toastr-progress-bar success",
            });
        }
        catch (e) {
            console.log('error ', e);
            if (e.error.statusCode === 409) {
                toast(<ToastrContent type="danger" title={"Error"} message={"Correo electrónico ya registrado"} />, {
                    progressClassName: "toastr-progress-bar danger",
                }) 
            } else showFailRequest();
        }
        finally {
            setLoading(false);
            getClubs();
        }
    }

    const remove = async (id) => {
        try {
            setLoading(true);
            await deleteClubService(id);


            toast(<ToastrContent type="success" title={"Éxito"} message={"El perfil ha sido eliminado."} />, {
                progressClassName: "toastr-progress-bar success",
            });
        }
        catch (e) {
            console.log('error setting preferences', e);
            showFailRequest();
        }
        finally {
            setLoading(false);
            getClubs();
        }
    }

    const suspend = async (id) => {
        try {
            setLoading(true);
            await suspentClubService(id);


            toast(<ToastrContent type="success" title={"Éxito"} message={"El perfil se ha cambiado."} />, {
                progressClassName: "toastr-progress-bar success",
            });
        }
        catch (e) {
            console.log('error setting preferences', e);
            showFailRequest();
        }
        finally {
            setLoading(false);
            getClubs();
        }
    }

    const edit = async (values, id) => {
        let data = {
            name: values.name,
            description: values.description,
            location: {
                zone: values.zone,
                address: values.direction,
                city: values.city,
                postalCode: values.zipCode,
                latitude: values.latitude,
                longitude: values.longitude,
            },
            maxPeople: values.maxPeople,
        };

        if (values.image?.base64) data.image = values.image?.base64;

        try {
            setLoading(true);
            await patchClubService(id, data);


            toast(<ToastrContent type="success" title={"Éxito"} message={"El perfil ha sido actualizado"} />, {
                progressClassName: "toastr-progress-bar success",
            });

        }
        catch (e) {
            console.log('error ', e);
            showFailRequest();
        }
        finally {
            setLoading(false);
            await getClubs();
        }
    }

    const unBlock = async (id) => {
        try {
            setLoading(true);
            const data = {
                active: true,
            }
            await patchClubService(id, data);

            toast(<ToastrContent type="success" title={"Éxito"} message={"La biblioteca ha sido habilitada."} />, {
                progressClassName: "toastr-progress-bar success",
            });
        }
        catch (e) {
            console.log('error setting preferences', e);
            showFailRequest();
        }
        finally {
            setLoading(false);
            await getClubs();
        }
    }

    return (
        <MyClubAdminComponent
            {...props}
            {...{ loading, submit, clubs, remove, edit, suspend, unBlock }}
        />
    )
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyClubAdmin))