import Button from 'components/elements/Button/Button'
import ConfirmationModal from 'components/elements/ConfirmationModal/ConfirmationModal'
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader'
import ReactModal from 'components/elements/ReactModal/ReactModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import EventModalContent from './ContentModalEvents/ContentModalEvents'
import './ModalEvents.scss'

const ModalEvents = (props) => {
    const { loading, handleDelete, modalProps, handleSubmit, handleEdit } = props
    const { event, type } = modalProps
    const [eventType, setEventType] = useState(type)

    useEffect(() => {
        if (modalProps.type) setEventType(modalProps.type)
    }, [modalProps])

    const eventPhoto = event?.image

    const formik = useFormik({
        initialValues: {
            headline: event?.headline || '',
            description: event?.description || '',
            image: event?.image || '',
            startingDate: (event && moment(event?.startingDate)) || '',
            endDate: (event?.endDate && moment(event?.endDate)) || '',
            startingTime: (event && moment(event?.startingDate)) || '',
            endTime: (event && moment(event?.endDate)) || '',
            maxPeople: event?.maxPeople || '',
            price: event ? event?.ticketTypeInfo?.ticketPrice || 10 : '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            headline: Yup.string().required('Obligatorio'),
            description: Yup.string().max(250, 'máximo de 250 caracteres').required('Obligatorio'),
            image: !eventPhoto && Yup.object().required('Obligatorio'),
            startingDate: Yup.string().required('Obligatorio'),
            startingTime: Yup.string().required('Obligatorio'),
            endDate: Yup.string()
                .nullable()
                .when('startingDate', (startingDate, schema) => {
                    return schema.test({
                        test: (endDate) => {
                            return !endDate || !moment(endDate).isBefore(startingDate, 'day')
                        },
                        message: 'La fecha de finalización debe ser posterior a la fecha de inicio.',
                    })
                }),
            endTime: Yup.string().when(
                ['endDate', 'startingDate', 'startingTime'],
                (endDate, startingDate, startingTime, schema) => {
                    return schema.test({
                        test: (endTime) => {
                            return (
                                !endTime ||
                                !moment(endDate).isSame(startingDate, 'day') ||
                                (moment(endDate).isSame(startingDate, 'day') && moment(endTime).isAfter(startingTime))
                            )
                        },
                        message: 'La hora de finalización debe ser posterior a la hora de inicio.',
                    })
                }
            ),
            maxPeople: Yup.number().required('Obligatorio'),
            price: Yup.number().required('Obligatorio'),
        }),
        onSubmit: (values) => {
            !event ? handleSubmit(values) : handleEdit(values, event.eventId, event.ticketTypeInfo.ticketTypeId)
            setEventType(null)
        },
    })

    useEffect(() => {
        if (formik?.values?.startingDate && !formik?.values?.endDate) {
            formik.setFieldValue('endDate', formik?.values?.startingDate)
        }
    }, [formik])

    return (
        <>
            <ReactModal
                open={['Create', 'Edit'].includes(eventType)}
                close={() => setEventType(null)}
                header={
                    <ContainerHeader
                        title={eventType === 'Edit' ? 'Editar evento' : 'Añadir evento'}
                        onClick={() => setEventType(null)}
                        modal
                    />
                }
                footer={
                    <Button
                        loading={loading}
                        type="button"
                        onClick={() => {
                            formik.handleSubmit()
                        }}
                    >
                        {eventType === 'Edit' ? 'Guardar cambios' : 'Añadir evento'}
                    </Button>
                }
            >
                <EventModalContent formik={formik} />
            </ReactModal>
            <ConfirmationModal
                open={eventType === 'Delete'}
                icon={<HiOutlineTrash />}
                title="¿Eliminar evento?"
                subtitle={`estás a punto de eliminar el evento ${event?.headline}`}
                confirmText="Eliminar"
                loading={loading}
                confirmButton={() => {
                    handleDelete(event.eventId, event.ticketTypeInfo.ticketTypeId)
                    setEventType(null)
                }}
                denyButton={() => setEventType(null)}
            />
        </>
    )
}
export default ModalEvents
