import React, { useEffect, useState } from 'react';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import Table from 'components/elements/Table/Table';
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader';
import moment from 'moment';
import './NotificationsComponent.scss'
import DetailButton from 'components/elements/DetailButton/DetailButton';
import ModalNotifications from './ModalNotifications/ModalNotifications';
import NoInfo from 'components/elements/NoInfo/NoInfo';
import { sortByDecreasingDate } from 'components/Utils/sortDate';

const NotificationsComponent = (props) => {
    const { notifications, submit, loading } = props;
    const [openModal, setOpenModal] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    const [dataIndex, setDataIndex] = useState();

    const columns = [
        {
            title: 'Fecha'.toUpperCase(),
            dataIndex: 'date',
            key: 'key',
            width: 220,
        },
        {
            title: 'Asunto'.toUpperCase(),
            dataIndex: 'subject',
            key: 'key',
            width: 380,
        },
        {
            title: 'Mensaje'.toUpperCase(),
            dataIndex: 'description',
            key: 'key',
            width: 680,
        },
        {
            title: 'Acción'.toUpperCase(),
            dataIndex: 'actions',
            key: 'key',
            width: 140,
        },
    ]

    useEffect(() => {
        if (notifications) {

            const aux = [];

            notifications.forEach((notification) => {
                aux.push({
                    key: notification.id,
                    date: moment(notification.date).format('LLL'),
                    subject: notification.title,
                    description: notification.body,
                    actions: <Actions clickButton={() => {
                        handleShowNotification();
                        setSelectedNotification({
                            date: moment(notification.date).format('DD/MM/YY'),
                            title: notification.title,
                            description: notification.body,
                        })
                    }
                    } />
                })
            })

            const sortedByDate = sortByDecreasingDate(aux, 'date','DD/MM/YY')

            setDataIndex([...sortedByDate]);
        }
    }, [notifications]);

    function handleSubmit(values) {
        submit(values);
        setOpenModal(false)
    }

    function handleSendNotification() {
        setSelectedNotification(null);
        setViewMode(false);
        setOpenModal(true)
    }

    function handleShowNotification() {
        setViewMode(true);
        setOpenModal(true)
    }

    return (
        <DashboardLayout>
            <div className="notifications-wrapper">
                <ContainerHeader title="Notificaciones" button="Enviar notificación" onClick={handleSendNotification} />
                {(dataIndex && dataIndex.length > 0) ? (
                    <Table data={dataIndex} columns={columns} />
                ) : (
                    <NoInfo text="Ninguna notificación" />
                )}
                <ModalNotifications
                    openModal={openModal}
                    handleModal={() => setOpenModal(!openModal)}
                    submit={handleSubmit}
                    loading={loading}
                    viewMode={viewMode}
                    notification={selectedNotification}
                />
            </div>
        </DashboardLayout>
    );
}

export default NotificationsComponent;

const Actions = (props) => {

    const { clickIcon, clickButton, title, description } = props
    return (
        < div className="action-wrapper" >
            {/* <div style={{ marginRight: '16px' }} onClick={clickIcon}>
                <img className="action-icon" src={require('assets/icons/sendArrow.png')} alt="infos" />
            </div> */}
            <DetailButton onClick={clickButton} >
                Ver detalles
            </DetailButton>
        </div>
    );
};
