import React from 'react'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import history from './services/history'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MdClose } from 'react-icons/md'

// Router Components
import PublicRoute from 'components/elements/PublicRoute/PublicRoute'
import PrivateRoute from 'components/elements/PrivateRoute/PrivateRoute'

// Auth Routes
import Login from './components/Login/containers/Login'
import Register from './components/Register/containers/Register'
import NewPassword from './components/NewPassword/containers/NewPassword'
import RecoveryPassword from './components/RecoveryPassword/containers/RecoveryPassword'

// Dashboard (all users) Routes
import Home from './components/Home/containers/Home'
import GeneralEntrance from 'components/Booking/GeneralEntrance/containers/GeneralEntrance'
import GeneralEntranceDetail from 'components/Booking/GeneralEntrance/GeneralEntranceDetail/containers/GeneralEntranceDetail'
import Stage from 'components/Booking/Stage/containers/Stage'
import StageDetails from 'components/Booking/Stage/StageDetails/containers/StageDetails'
import Notifications from 'components/Notifications/container/Notifications'
import Events from './components/Events/containers/Events'
import Publications from 'components/Publications/container/Publications'
import Zones from 'components/Zones/containers/Zones'
import EditZones from 'components/Zones/EditZones/containers/EditZones'
import Profile from 'components/Profile/containers/Profile'
import Products from 'components/Products/container/Products'

// Admin Routes
import MyClubAdmin from './components/Admin/MyClubAdmin/containers/MyClubAdmin'

import './App.scss'
import CheckIn from 'components/CheckIn/containers/CheckIn'
import Promotions from 'components/Promotions/containers/Promotions'

function App() {
    const CloseButton = () => <MdClose className="icon toastr-close" />

    return (
        <Provider store={store}>
            <Router history={history}>
                <div className="toastr-div">
                    <ToastContainer
                        toastClassName="toastr-wrapper"
                        autoClose={5000}
                        hideProgressBar={false}
                        pauseOnHover
                        closeButton={<CloseButton />}
                    />
                </div>
                <div className="App">
                    <Switch>
                        <PublicRoute restricted component={Login} path="/" exact />
                        <PublicRoute restricted component={Login} path="/login" />
                        <PublicRoute restricted component={Register} path="/register" />
                        <PublicRoute restricted component={NewPassword} path="/recover" />
                        <PublicRoute restricted component={RecoveryPassword} path="/recovery-password" />

                        {/* Club Routes */}
                        <PrivateRoute
                            role="Club"
                            component={GeneralEntrance}
                            path="/club/:id/booking/general-entrance"
                            exact
                        />
                        <PrivateRoute
                            role="Club"
                            component={GeneralEntranceDetail}
                            path="/club/:id/booking/general-entrance/details/:idBooking"
                        />
                        <PrivateRoute role="Club" component={Stage} path="/club/:id/booking/stage" exact />
                        <PrivateRoute
                            role="Club"
                            component={StageDetails}
                            path="/club/:id/booking/stage/details/:bookingId"
                        />
                        <PrivateRoute role="Club" component={Notifications} path="/club/:id/notifications" />
                        <PrivateRoute role="Club" component={Events} path="/club/:id/events" />
                        <PrivateRoute role="Club" component={Profile} path="/club/:id/my-club" exact />
                        <PrivateRoute role="Club" component={Profile} path="/club/:id/my-club/my-profile" />
                        <PrivateRoute role="Club" component={Products} path="/club/:id/my-club/products" />
                        <PrivateRoute role="Club" component={Profile} path="/club/:id/my-club/edit-profile" />
                        <PrivateRoute role="Club" component={Publications} path="/club/:id/my-club/publications" />
                        <PrivateRoute role="Club" component={Promotions} path="/club/:id/my-club/promotions" />
                        <PrivateRoute role="Club" component={Zones} path="/club/:id/my-club/zones" />
                        <PrivateRoute role="Club" component={EditZones} path="/club/:id/my-club/zone/:zoneId" />
                        <PrivateRoute role="Club" component={EditZones} path="/club/:id/my-club/edit-zone/:zoneId" />
                        <PrivateRoute role="Club" component={EditZones} path="/club/:id/my-club/create-zones" />
                        <PrivateRoute
                            role="Club"
                            component={CheckIn}
                            path="/checkin/booking/:bookingId/ticket/:ticketId"
                        />

                        {/* Admin Routes */}
                        <PrivateRoute role="Admin" component={MyClubAdmin} path="/admin/:id/my-club" />
                    </Switch>
                </div>
            </Router>
        </Provider>
    )
}

export default App
