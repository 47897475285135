import customFetch from './customFetch'
import { endpoints } from './endpoints'
import { getUserId, getUserToken } from './utils'

export const getPostsSocialNetworkService = async () => {
    const token = await getUserToken()

    return customFetch(endpoints.postsSocialNetwork, {
        method: 'GET',
        token,
    })
}

export const getUserPostsSocialNetworkService = async () => {
    const token = await getUserToken()
    const userId = await getUserId()

    return customFetch(`${endpoints.postsSocialNetwork}/users/${userId}`, {
        method: 'GET',
        token,
    })
}

export const getPostSocialNetworkService = async (postId) => {
    const token = await getUserToken()

    return customFetch(`${endpoints.postsSocialNetwork}/${postId}`, {
        method: 'GET',
        token,
    })
}

export const changePostSocialNetworkService = async (data, postId) => {
    const token = await getUserToken()

    return customFetch(`${endpoints.postsSocialNetwork}/${postId}`, {
        method: 'PATCH',
        bodyReq: data,
        token,
    })
}

export const writePostSocialNetworkService = async (values) => {
    const token = await getUserToken()

    return customFetch(endpoints.postsSocialNetwork, {
        method: 'POST',
        bodyReq: values,
        token,
    })
}

export const deletePostSocialNetworkService = async (id) => {
    const token = await getUserToken()

    return customFetch(`${endpoints.postsSocialNetwork}/${id}`, {
        method: 'DELETE',
        token,
    })
}

export const uploadImageService = async ({ uri, type, name }) => {
    return new Promise((resolve, reject) => {
        var data = new FormData()
        data.append(`files[]`, { uri, type, name }, uri)

        var xhr = new XMLHttpRequest()
        xhr.withCredentials = true

        xhr.addEventListener('load', function () {
            resolve(JSON.parse(xhr.responseText))
        })

        xhr.addEventListener('error', function () {
            reject(xhr.responseText)
        })

        xhr.open('POST', endpoints.attachments)
        xhr.setRequestHeader('Content-Type', 'multipart/form-data')
        xhr.setRequestHeader('accept', 'application/json')
        xhr.setRequestHeader('Authorization', 'Bearer ' + getUserToken())
        xhr.send(data)
    })
}

export const getAttachments = async (id) => {
    const token = await getUserToken()

    return customFetch(`${endpoints.attachments}/${id}`, {
        method: 'GET',
        token,
    })
}

export const writeAttachments = async (attachments) => {
    const token = await getUserToken()

    return customFetch(endpoints.attachments, {
        method: 'POST',
        bodyReq: attachments,
        token,
    })
}

export const deletePostAttachment = async (path, postId) => {
    const token = await getUserToken()

    return customFetch(`${endpoints.postsSocialNetwork}/${postId}/attachments/${path}`, {
        method: 'PATCH',
        token,
    })
}
