import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getCheckinDetailsService } from 'services/bookingService';
import { getUserInfoService } from 'services/user';
import { getBookingRefundChargeId, postRefoundBooking } from 'services/userPurchase';
import CheckInComponent from '../components/CheckInComponent';
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';

const CheckIn = (props) => {
    const { bookingId, ticketId } = useParams();
    const [loading, setLoading] = useState(true);
    const [checkinInfos, setCheckinInfos] = useState();
    const [refundStatus, setRefundStatus] = useState(null);

    useEffect(() => {
        getCkeckinInfo();
    }, [])

    const getCkeckinInfo = async () => {
        try {
            const res = await getCheckinDetailsService(bookingId, ticketId);

            const ticketsDataPromises = res?.ReservationInfos?.tickets.map(async (ticket) => {
                const userData = await getUserInfoService(ticket.idUser);
                return {
                    ...ticket,
                    userData: userData?.features
                }
            })

            const ticketsData = await Promise.all(ticketsDataPromises);

            const bookingData = {
                ...res,
                ticketsData
            }

            if (res) setCheckinInfos(bookingData);
        }
        catch (e) {
            console.log('error setting preferences', e);
            throw (e);
        }
        finally {
            setLoading(false);
        }
    }

    const refoundBooking = async () => {
        try {
            setLoading(true)
            const res = await postRefoundBooking(ticketId)
            setRefundStatus("done")
        }
        catch (e) {
            console.log('error updating', e);
            toast(<ToastrContent type="danger" title={"Error"} message={"No se ha podido hacer el reembolso."} />, {
                progressClassName: "toastr-progress-bar danger",
            })
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <CheckInComponent
            {...props}
            {...{ bookingId, ticketId, loading, checkinInfos, refoundBooking, refundStatus }}
        />
    )
}
export default CheckIn;