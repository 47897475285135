import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getTicketTypeService = async (idClub) => {
    try {
        let response = await customFetch(`${endpoints.ticketTypes}?filter={"where":{"and":[{"idClub": "${idClub}"}]}}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const postTicketTypeService = async (values) => {
    try {
        let response = await customFetch(`${endpoints.ticketTypes}`, {
            method: "POST",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const patchTicketTypeService = async (values, ticketId) => {
    try {
        let response = await customFetch(`${endpoints.ticketTypes}/${ticketId}`, {
            method: "PATCH",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const deleteTicketTypeService = async (ticketId) => {
    try {
        let response = await customFetch(`${endpoints.ticketTypes}/${ticketId}`, {
            method: "DELETE",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}