import React, { useEffect, useState } from 'react';
import ProductsComponent from '../components/ProductsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUser } from 'redux/actions/userActions';
import { deleteProductService, getProductsService, patchProductService, postProductService } from 'services/productsService';
import { toast } from 'react-toastify';
import ToastrContent from 'components/elements/Toastr/Toastr';
import { getCategoriesService } from 'services/categoryService';

const Products = (props) => {
    const { user } = props;
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState();
    const [categories, setCategories] = useState();

    useEffect(() => {
        getProducts()
        getCategories()
    }, [])

    function showFailRequest() {
        return (
            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde"} />, {
                progressClassName: "toastr-progress-bar danger",
            })
        )
    }

    const getProducts = async () => {
        try {
            const res = await getProductsService(user.id);

            if (res) setProducts(res);
        }
        catch (e) {
            console.log('error setting preferences', e);
            showFailRequest();
            throw e;
        }
    }
    
    const getCategories = async () => {
        try {
            const res = await getCategoriesService();

            if (res) setCategories(res);
        }
        catch (e) {
            console.log('error setting preferences', e);
            showFailRequest();
            throw e;
        }
    }

    const submit = async (values) => {
        try {
            setLoading(true);
            const data = {
                idClub: user.id,
                name: values.name,
                price: Number(values.price),
                isAvailable: values.available.value,
                categoryId: values.category.value,
            }

            if (values.image) data.image = values.image.base64;

            await postProductService(data);
            getProducts();
        
            toast(<ToastrContent type="success" title={"Éxito"} message={"El producto ha sido creado"} />, {
                progressClassName: "toastr-progress-bar success",
            })
        }
        catch (e) {
            console.log('error setting preferences', e);
            showFailRequest();
            throw e;
        }
        finally {
            setLoading(false);
        }
    }

    const remove = async (id) => {
        try {
            setLoading(true);
            await deleteProductService(id);
            
            getProducts();

            toast(<ToastrContent type="success" title={"Éxito"} message={"El producto ha sido eliminado"} />, {
                progressClassName: "toastr-progress-bar success",
            })
        }
        catch (e) {
            console.log('error setting preferences', e);
            showFailRequest();
            throw (e)
        }
        finally {
            setLoading(false);
        }
    }

    const edit = async (values, id) => {
        try {
            setLoading(true);

            const data = {
                idClub: user.id,
                name: values.name,
                price: Number(values.price),
                isAvailable: values.available.value,
                categoryId: values.category.value,
            }

            if (values.image.base64) data.image = values.image.base64;

            await patchProductService(data, id);
            getProducts();

            toast(<ToastrContent type="success" title={"Éxito"} message={"El producto ha sido actualizado"} />, {
                progressClassName: "toastr-progress-bar success",
            })
        }
        catch (e) {
            console.log('error setting preferences', e);
            showFailRequest();
            throw (e);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <ProductsComponent
            {...props}
            {...{ products, user, loading, submit, remove, edit, categories }}
        />
    );
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products))