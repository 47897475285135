import React from 'react';
import Modal from 'react-modal';
import './ReactModal.scss';

const ReactModal = ({ open, close, children, header, footer }) => {
    const customStyles = {
        overlay: {
            background: "#00000080",
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            overflow: 'auto',
            borderRadius: '27px',
            maxHeight: '90%',
            maxWidth: '90%',
            display: 'flex',
            flexDirection: 'column',
            background: 'none'
        }
    }

    return (
        <Modal
            isOpen={open}
            style={customStyles}
        >
            <div className="react-modal">
                <div className="modal-header">
                    {header}
                </div>
                <div className="modal-body">
                    {children}
                </div>
                {footer && <div className="modal-footer">
                    {footer}
                </div>}
            </div>
        </Modal>
    );
};

export default ReactModal;