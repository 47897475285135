import React from 'react'
import { Table as AntdTable, Empty as AntdEmpty } from 'antd'
import 'antd/dist/antd.css'
import './Table.scss'

const Table = (props) => {
    const { data, columns, total, loading } = props
    const { Column } = Table

    const pagination = {
        pageSize: 8,
        hideOnSinglePage: true,
    }

    return (
        <div className="table-wrapper">
            <AntdTable
                dataSource={data}
                rowClassName="table_row"
                pagination={pagination}
                loading={loading || !data || data.length === 0}
                scroll={{ x: 'max-content' }}
                columns={columns}
            >
                {/*columns.map((column) => {
                    return (
                        <Column
                            title={column.title}
                            dataIndex={column.dataIndex}
                            key={column.key}
                            width={column.width}
                        />
                    )
                })*/}
            </AntdTable>
        </div>
    )
}
export default Table
