import React from 'react';
import ImagePicker from "components/elements/ImagePicker/ImagePicker";
import Input from "components/elements/Input/Input";
import './ContentModalEvents.scss';

export default function EventModalContent(props) {
    const { formik } = props;
    return (
        <div className="content-modalEvents-wrapper">
            <div className="inputs-wrapper">
                <div className="input-wrapper">
                    <ImagePicker
                        id="image"
                        formik={formik}
                        center
                        subtitle="Imagen del evento"
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="headline"
                        formik={formik}
                        type="text"
                        label="Nombre"
                        placeholder="Nombre"
                    />
                </div>
                <div className="row">
                    <div className="input-wrapper row-first">
                        <Input
                            id="startingDate"
                            type="date"
                            formik={formik}
                            label="Fecha de inicio"
                            placeholder="Fecha de inicio"
                        />
                    </div>
                    <div className="input-wrapper ">
                        <Input
                            id="startingTime"
                            type="time"
                            formik={formik}
                            label="Hora de inicio"
                            placeholder="Hora de inicio"
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="input-wrapper row-first">
                        <Input
                            id="endDate"
                            type="date"
                            formik={formik}
                            label="Fecha final"
                            placeholder="Fecha final"
                        />
                    </div>

                    <div className="input-wrapper">
                        <Input
                            id="endTime"
                            type="time"
                            formik={formik}
                            label="Hora final"
                            placeholder="Hora final"
                        />
                    </div>
                </div>
                <div className="input-wrapper">
                    <Input
                        id="maxPeople"
                        formik={formik}
                        type="text"
                        label="Aforo máximo"
                        placeholder="Introduce el número máximo de personas"
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="price"
                        formik={formik}
                        type="text"
                        label="Precio"
                        placeholder="Introduce el precio"
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="description"
                        formik={formik}
                        type="textarea"
                        label="Descripción"
                        placeholder="Introduce una descripción para tu evento"
                    />
                </div>

            </div>
        </div>
    );
}
