/**
 *
 * @param {*} URL to fetch
 * @param {*} method GET/POST/PUT/...
 * @param  bodyReq request body
 * @param  params  url params
 * @param  token authorizathion token
 * @returns  response json
 * @author Adriller Ferreira
 */

export default async function customFetch(URL, { method = "GET", bodyReq = null, params = null, token = null, trackPerformance =true }) {
    if (!URL) return { error: "invalid URL" };
    let requestDelay = new Date().getTime();
    const finalURL = params ? URL + "/" + params : URL;
    const headers = token
        ? {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
          }
        : { "Content-Type": "application/json" };
    let response;
    try {
        response = await fetch(finalURL, {
            method,
            // params:  params ? JSON.stringify(params) : null,
            body: bodyReq ? JSON.stringify(bodyReq) : null,
            headers,
        });
    } catch (e) {
        // console.log(e);
        throw e;
    }

    let jsonResponse = response;

    if (response.headers && response.headers.get("Content-Type")) {
        if (response.headers.get("Content-Type").indexOf("application/json") > -1) {
            try {
                jsonResponse = await response.json();
            } catch (err) {
                throw err;
            }
        }
    }

    return jsonResponse;
}