import React, { useEffect, useState } from 'react'
import PublicationsContainer from '../components/PublicationsContainer'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateUser } from 'redux/actions/userActions'
import moment from 'moment'
import { toast } from 'react-toastify'
import ToastrContent from 'components/elements/Toastr/Toastr'
import {
    changePostSocialNetworkService,
    deletePostSocialNetworkService,
    getUserPostsSocialNetworkService,
    writePostSocialNetworkService,
    deletePostAttachment,
    uploadImageService,
} from 'services/postSocialNetwork'
import _ from 'lodash'

const Publications = (props) => {
    const { user, history, updateUser } = props
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getPosts()
    }, [])

    const failRequest = () => {
        return toast(<ToastrContent type="danger" title={'Error'} message={'Inténtalo más tarde'} />, {
            progressClassName: 'toastr-progress-bar danger',
        })
    }

    const getPosts = async () => {
        try {
            setLoading(true)
            const resp = await getUserPostsSocialNetworkService()
            const postWithoutPromotion = resp.filter((post) => !post.isPromotion)
            if (postWithoutPromotion) setPosts(postWithoutPromotion)
        } catch (e) {
            console.log('error setting preferences', e)
        } finally {
            setLoading(false)
        }
    }

    const submit = async (value) => {
        try {
            setLoading(true)

            let data = {
                content: value.description,
                publishedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                public: true,
                isClub: true,
            }

            if (value.file?.base64) {
                data.attachments = [
                    {
                        attachment: value.file?.base64,
                        attachmentType: 'IMG',
                    },
                ]
            } else {
                data.attachments = [
                    {
                        attachment: value.file?.id,
                        attachmentType: 'VIDEO',
                    },
                ]
            }

            const res = await writePostSocialNetworkService(data)

            getPosts()
            toast(<ToastrContent type="success" title={'Éxito'} message={'La publicación ha sido creada'} />, {
                progressClassName: 'toastr-progress-bar success',
            })
        } catch (e) {
            console.log('error setting preferences', e)
            failRequest()
        } finally {
            setLoading(false)
        }
    }

    const edit = async (value) => {
        try {
            setLoading(true)
            let data = {
                content: value.description,
                publishedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                public: true,
                isClub: true,
            }

            if (value.file.base64 || value.file.attachmentType === 'IMG') {
                if (!value?.file?.path) {
                    data.attachments = [
                        {
                            attachment: value?.file?.base64,
                            attachmentType: 'IMG',
                        },
                    ]

                    const selectedPost = posts.filter((post) => post.id === value.key)[0]
                    const imageToDelete = selectedPost.attachments[0].attachment
                    const deleteImage = await deletePostAttachment(encodeURIComponent(imageToDelete), selectedPost.id)
                } else {
                }
            }

            if (value.file.attachmentType === 'VIDEO' || (value.file.path && value.file.attachmentType !== 'IMG')) {
                if (!!value.file.id) {
                    data.attachments = [
                        {
                            attachment: value.file.id,
                            attachmentType: 'VIDEO',
                        },
                    ]

                    const selectedPost = posts.filter((post) => post.id === value.key)[0]
                    const imageToDelete =
                        selectedPost.attachments[0].attachmentType === 'IMG'
                            ? selectedPost.attachments[0].attachment
                            : null
                    imageToDelete && (await deletePostAttachment(encodeURIComponent(imageToDelete), selectedPost.id))
                }
            }

            const res = await changePostSocialNetworkService(data, value.key)
            toast(<ToastrContent type="success" title={'Éxito'} message={'La publicación ha sido actualizada'} />, {
                progressClassName: 'toastr-progress-bar success',
            })
            getPosts()
        } catch (e) {
            console.log('error setting preferences', e)
            failRequest()
        } finally {
            setLoading(false)
        }
    }

    const remove = async (postId) => {
        try {
            setLoading(true)
            const res = await deletePostSocialNetworkService(postId)

            toast(<ToastrContent type="success" title={'Éxito'} message={'La publicación ha sido eliminada'} />, {
                progressClassName: 'toastr-progress-bar success',
            })

            getPosts()
        } catch (e) {
            console.log('error setting preferences', e)
            failRequest()
        } finally {
            setLoading(false)
        }
    }

    return <PublicationsContainer {...props} {...{ posts, history, user, loading, submit, edit, remove }} />
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Publications))
