import React, { useState, useEffect } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import './SecondaryButton.scss';

const SecondaryButton = ({ infos }) => {
    const { title, options } = infos;
    const [expand, setExpand] = useState(false);

    function clickValidate(click) {
        if (click) return (
            click(),
            setExpand(false)
        );
    }

    return (
        <div className="secondary-wrapper">
            <button
                className="subtitle-button-wrapper"
                onClick={() => setExpand(!expand)}>
                {title}
                <div className="icon-option-wrapper">
                    {expand ? <MdExpandLess /> : <MdExpandMore />}
                </div>
            </button>
            {expand ? (
                <div className="options-wrapper">
                    {options.map((option) => (
                        <label
                            className="options-items-wrapper"
                            htmlFor={option.id}
                            onClick={() => clickValidate(option?.onClick)}
                        >
                            {option.icon}
                            {option.title}
                        </label>
                    ))}
                </div>
            ) : (<></>)}
        </div>
    )
}
export default SecondaryButton;