import React, { useState, useEffect } from 'react';
import NewPasswordComponents from "../components/NewPasswordComponents";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import { recoverPasswordService } from "services/user"
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';

const NewPassword = (props) => {
    const { updateUser, history } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async (values) => {
        setLoading(true);

        const token = window.location.href.split('?token=')[1];

        const data = {
            newPassword: values.password,
            resetPasswordToken: token,
        }

        try {
            const response = await recoverPasswordService(data);
            updateUser(response);
            history.push("/login");
            setLoading(false);

            toast(<ToastrContent type="success" title={"Éxito"} message={"La contraseña se ha cambiado"} />, {
                progressClassName: "toastr-progress-bar success",
            });
        } catch (error) {
            setError(error.message);
            setLoading(false);

            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde"} />, {
                progressClassName: "toastr-progress-bar danger",
            });
        }
    };

    return <NewPasswordComponents
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        history={history}
    />;
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(NewPassword));