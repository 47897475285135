import React from 'react'
import './AvailableButton.scss';

const AvailableButton = ({children, unavailable}) => {
    return (
        <div className="available-button-wrapper">
            <div className={unavailable ? "unavailable" : "available"}>
                {children}
            </div>
        </div>
    );
}

export default AvailableButton;