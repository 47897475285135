import React, { useState, useEffect } from 'react'
import StageDetailsComponent from '../components/StageDetailsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { updateUser } from 'redux/actions/userActions';
import { getBookingDetailsService } from 'services/bookingService';
import { toast } from 'react-toastify';
import ToastrContent from 'components/elements/Toastr/Toastr';

const StageDetailsContainer = (props) => {
    const { history, user } = props;
    const [loading, setLoading] = useState(false);
    const [booking, setBooking] = useState();
    const [requestFail, setRequestFail] = useState();

    let { bookingId } = useParams();

    useEffect(() => {
        getBooking();
    }, [])

    const getBooking = async () => {
        try {
            setLoading(true);

            const res = await getBookingDetailsService(bookingId);
            if (res) setBooking(res);
        }
        catch (e) {
            console.log('error setting preferences', e);

            setRequestFail(true)
            toast(<ToastrContent type='danger' title={'Error'} message={'Inténtalo más tarde'} />, {
                progressClassName: 'toastr-progress-bar danger',
            })
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <StageDetailsComponent
            {...props}
            {...{ history, user, booking, bookingId, loading, requestFail }}
        />
    )
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StageDetailsContainer))