import React, { useEffect, useState } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import './MyClubAdminComponent.scss';
import ContainerHeader from "components/elements/ContainerHeader/ContainerHeader";
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { MdBlock } from 'react-icons/md';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import Table from "components/elements/Table/Table";
import ContactInfo from "components/elements/Table/ContactInfo/ContactInfo";
import ConfirmationModal from "components/elements/ConfirmationModal/ConfirmationModal";
import Logo from 'assets/images/logo.png';
import ModalClubsAdmin from "./ModalClubsAdmin/ModalClubsAdmin";
import NoInfo from "components/elements/NoInfo/NoInfo";
import animationData from "assets/lotties/loading-white.json";
import Lottie from "react-lottie";

const MyClubAdminComponent = (props) => {
    const { loading, submit, clubs, remove, edit, suspend, unBlock } = props;

    const [modalHandler, setModalHandler] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [dataClubs, setDataClubs] = useState();
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmationInfos, setConfirmationInfos] = useState({});
    const [selectedClub, setSelectedClub] = useState({});

    function handleEditClub(club) {
        setSelectedClub(club);
        setEditMode(true);
        setModalHandler(true);
    }

    function finalize() {
        setConfirmationModal(false);
        setDataClubs();
    }

    function handleSubmit(values) {
        submit(values);
        setModalHandler(false);
        setDataClubs();
    }

    function handleEdit(values, id) {
        edit(values, id);
        setModalHandler(false);
        setDataClubs();
    }

    function confirmationHandler({ type, club, icon }) {
        switch (type) {
            case 'Delete': return (
                <ConfirmationModal
                    open={confirmationModal}
                    close={() => setConfirmationModal(false)}
                    confirmButton={() => {
                        remove(club.idClub);
                        finalize();
                    }}
                    denyButton={() => setConfirmationModal(false)}
                    confirmText="Eliminar"
                    icon={icon}
                    title="¿Eliminar discoteca?"
                    subtitle={`estás a punto de eliminar la discoteca ${club.name}`}
                />
            );
            case 'Block': return (
                <ConfirmationModal
                    open={confirmationModal}
                    close={() => setConfirmationModal(false)}
                    confirmButton={() => {
                        suspend(club.idClub)
                        finalize();
                    }}
                    denyButton={() => setConfirmationModal(false)}
                    confirmText="Suspender"
                    icon={icon}
                    title="¿Suspender discoteca?"
                    subtitle={`estás a punto de suspender la discoteca ${club.name}`}
                />
            );
            case 'unBlock': return (
                <ConfirmationModal
                    open={confirmationModal}
                    close={() => setConfirmationModal(false)}
                    confirmButton={() => {
                        unBlock(club.idClub)
                        finalize();
                    }}
                    denyButton={() => setConfirmationModal(false)}
                    confirmText="Desuspender"
                    icon={icon}
                    title="Desuspender discoteca?"
                    subtitle={`estás a punto de desuspender la discoteca ${club.name}`}
                />
            );
            default: break;
        }
    }

    function handleBlockDeleteClub(type, club, icon) {
        setConfirmationInfos({ type, club, icon })
        setConfirmationModal(true);
    }

    useEffect(() => {
        const dataIndex = [];
        if (clubs && clubs.length > 0) {
            clubs.forEach((club) => {
                const validate = club.image && (club.image.includes('http') || club.image.includes('base64'));
                let image;
                if (!validate) image = Logo
                dataIndex.push(
                    {
                        key: club.idClub,
                        name: <ContactInfo image={image || club.image} name={club.name} key={club.idClub} />,
                        zone: club.location.zone || '-',
                        adrees: club.location.address || '-',
                        city: club.location.city || '-',
                        zipCode: club.location.postalCode || '-',
                        maxPeople: club.maxPeople,
                        actions: <Actions club={club} />,
                    },
                )
            })

            setDataClubs([...dataIndex]);
        }

    }, [clubs])

    const Actions = ({ club }) => {
        return (
            <div className="action-buttons-wrapper">
                <div
                    onClick={() => handleEditClub(club)}
                ><FiEdit3 /></div>
                <div
                    onClick={() => {
                        setConfirmationModal(true);
                        handleBlockDeleteClub('Delete', club, <HiOutlineTrash />)
                    }}
                ><HiOutlineTrash /></div>
                <div
                    onClick={() => {
                        setConfirmationModal(true);
                        club.active === false ? (
                            handleBlockDeleteClub('unBlock', club, <AiOutlinePlayCircle />)
                            ) : (
                            handleBlockDeleteClub('Block', club, <MdBlock />)
                        )
                    }}
                >{club.active === false ? (<AiOutlinePlayCircle />) : (<MdBlock />)}</div>
            </div>
        );
    }

    function handleCloseModal() {
        setModalHandler(false);
        setEditMode(false);
        setSelectedClub({});
    }
    return (
        <DashboardLayout>
            <div className="club-admin-wrapper">
                <ContainerHeader title="Discotecas registradas" button="Añadir discoteca" onClick={() => setModalHandler(true)} />

                {loading ?
                    <div className="loading-wrapper">
                        <Lottie options={defaultOptions} height={140} width={140} />
                    </div> :
                    dataClubs?.length > 0 ? <Table data={dataClubs} columns={columns} /> : <NoInfo text="No hay discotecas" />
                }
                {modalHandler && <ModalClubsAdmin
                    editMode={editMode}
                    loading={loading}
                    handleCloseModal={handleCloseModal}
                    modalHandler={modalHandler}
                    handleSubmit={handleSubmit}
                    club={selectedClub}
                    edit={handleEdit}
                />}
                {confirmationHandler(confirmationInfos)}
            </div>
        </DashboardLayout>
    );
}

const columns = [
    {
        title: 'Discoteca'.toUpperCase(),
        dataIndex: 'name',
        key: 'key',
        width: 260,
    },
    {
        title: 'Zona'.toUpperCase(),
        dataIndex: 'zone',
        key: 'key',
        width: 200,
    },
    {
        title: 'Dirección'.toUpperCase(),
        dataIndex: 'adrees',
        key: 'key',
        width: 340,
    },
    {
        title: 'Ciudad'.toUpperCase(),
        dataIndex: 'city',
        key: 'key',
        width: 200,
    },
    {
        title: 'Código Postal'.toUpperCase(),
        dataIndex: 'zipCode',
        key: 'key',
        width: 200,
    },
    {
        title: 'Aforo Mximo'.toUpperCase(),
        dataIndex: 'maxPeople',
        key: 'key',
        width: 190,
    },
    {
        title: 'acción'.toUpperCase(),
        dataIndex: 'actions',
        key: 'key',
        width: 70,
    },
]

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
export default MyClubAdminComponent;