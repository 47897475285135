import React, { useState, useEffect } from 'react'
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader';
import NoInfo from 'components/elements/NoInfo/NoInfo';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { RiMap2Line } from 'react-icons/ri';
import { FiUpload } from 'react-icons/fi';
import './ZonesComponent.scss';
import ReactModal from 'components/elements/ReactModal/ReactModal';
import Input from 'components/elements/Input/Input';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import ConfirmationModal from 'components/elements/ConfirmationModal/ConfirmationModal';
import Placeholder from 'assets/images/placeholder.png';

const ZonesComponent = (props) => {
    const { zones, submit, user, history, submitImage, remove, loading } = props;
    const [openModal, setOpenModal] = useState(false);
    const [showBack, setShowBack] = useState(false);
    const [selectedZone, setselectedZone] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            image: "",
            name: "",
            reservation: "",
        },
        validationSchema: Yup.object().shape({
            image: Yup.object().required("Obligatorio"),
            name: Yup.string().required("Obligatorio"),
            reservation: Yup.object().required("Obligatorio"),
        }),
        onSubmit: (values) => { submit(values) },
    })

    const mapFormik = useFormik({
        initialValues: {
            image: ""
        },
        validationSchema: Yup.object().shape({
            image: Yup.object().required("Obligatorio"),
        }),
        onSubmit: (values) => { console.log(values) },
    })

    useEffect(() => {
        if (mapFormik.values?.image)
            submitImage(mapFormik.values?.image, user.id);
    }, [mapFormik.values?.image])

    function removeZone(zone) {
        setselectedZone(zone)
        setConfirmationModal(true)
    }


    return (
        <DashboardLayout showBack={showBack} handleBack={() => {
            formik.resetForm();
            setShowBack(false);
            history.push(`/club/${user.id}/my-club/zones`)
        }} title={showBack ? <h3>Añadir zona</h3> : (null)}>
            <div className="zones-wrapper">
                {<ContainerHeader
                    title="Zonas"
                    button="Añadir zona"
                    onClick={() => history.push(`/club/${user.id}/my-club/create-zones`)}
                    secondaryButton={{
                        title: 'Mapa Discoteca',
                        options: [
                            {
                                title: 'Ver',
                                icon: <RiMap2Line />,
                                onClick: () => setOpenModal(true)
                            },
                            {
                                title: 'Subir mapa',
                                icon: <FiUpload />,
                                id: 'image'
                            },
                        ]
                    }}
                />}
                <Input
                    type="file"
                    id={'image'}
                    formik={mapFormik}
                    formats={['png', 'jpg', 'jpeg']}
                />
                {!zones || (zones && zones.length <= 0) ? (
                    <NoInfo />
                ) : (
                    <div
                        className="zones-content-wrapper"
                        style={zones.length <= 3 ? {gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 380px))'} : {}}
                    >
                        {zones.map((zone) => (
                            <Zone
                                zone={zone}
                                user={user}
                                history={history}
                                remove={removeZone}
                            />
                        ))}
                    </div>
                )}

                <ConfirmationModal
                    open={confirmationModal}
                    close={() => setConfirmationModal(false)}
                    loading={loading}
                    confirmButton={() => {
                        remove(selectedZone.zoneId)
                        setConfirmationModal(false)
                    }}
                    denyButton={() => setConfirmationModal(false)}
                    confirmText="Eliminar"
                    icon={<HiOutlineTrash />}
                    title="¿Eliminar discoteca?"
                    subtitle={`estás a punto de eliminar la discoteca ${selectedZone.name}`}
                />

                <ReactModal
                    open={openModal}
                    close={() => setOpenModal(false)}
                    header={(
                        <ContainerHeader
                            title="Mapa discoteca"
                            onClick={() => setOpenModal(false)}
                            modal
                        />
                    )}
                    footer={<></>}
                >
                    <img className="zones-image-map-wrapper" src={user.mapImage || Placeholder} alt="map Zona" />
                </ReactModal >
            </div>
        </DashboardLayout>
    )
}
export default ZonesComponent;

const Zone = (props) => {
    const { zone, history, remove, user } = props;
    return (
        <div className="zone-component" style={{ backgroundImage: `url(${zone.image || Placeholder})` }}>
            <h5>
                {zone.name}
            </h5>
            <div className="color-opacity">
                <div className="icons-zone-wrapper">
                    <FiEdit3 onClick={() => history.push(`/club/${user.id}/my-club/edit-zone/${zone.zoneId}`)} />
                    <HiOutlineTrash onClick={() => remove(zone)}
                    />
                </div>
            </div>
        </div>
    );
}