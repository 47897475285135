import React from 'react'
import './ContactInfo.scss'
import { FaPlay } from 'react-icons/fa'
import Placeholder from 'assets/images/placeholder.png'

const ContactInfo = ({ image, name, contactKey, video }) => {
    return (
        <div className="info-wrapper" key={contactKey}>
            <div className="info-image">
                <img className="image-wrapper" src={image || Placeholder} alt={name} />
                {video && (
                    <div className="playIcon">
                        <FaPlay size={24} />
                    </div>
                )}
            </div>
            {name}
        </div>
    )
}

export default ContactInfo
