import React from 'react';
import ImagePicker from "components/elements/ImagePicker/ImagePicker";
import Input from "components/elements/Input/Input";
import './ContentModalClub.scss';

export default function ModalContent(props) {
    const { formik } = props;
    return (
        <div className="content-modalClub-wrapper">
            <div className="inputs-wrapper">
                <div className="input-wrapper">
                    <ImagePicker
                        id="image"
                        formik={formik}
                        center
                        subtitle="Foto de perfil"
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="email"
                        formik={formik}
                        type="text"
                        label="Email Admin"
                        placeholder="Email del administrador"
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="name"
                        formik={formik}
                        type="text"
                        label="Nombre"
                        placeholder="Nombre"
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="zone"
                        formik={formik}
                        type="text"
                        label="Zona"
                        placeholder="Introduce la zona"
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="direction"
                        formik={formik}
                        type="text"
                        label="Dirección"
                        placeholder="Introduce la dirección"
                    />
                </div>
                <div className="row">
                    <div className="input-wrapper">
                        <Input
                            id="city"
                            formik={formik}
                            type="text"
                            label="Ciudad"
                            placeholder="Ciudad"
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="zipCode"
                            formik={formik}
                            type="text"
                            label="Código Postal"
                            placeholder="Código Postal"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="input-wrapper">
                        <Input
                            id="latitude"
                            formik={formik}
                            type="text"
                            label="Latitud"
                            placeholder="Latitud"
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="longitude"
                            formik={formik}
                            type="text"
                            label="Longitud"
                            placeholder="Longitud"
                        />
                    </div>
                </div>
                <div className="input-wrapper">
                    <Input
                        id="maxPeople"
                        formik={formik}
                        type="text"
                        label="Aforo máximo"
                        placeholder="Aforo máximo"
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        id="description"
                        formik={formik}
                        type="textarea"
                        label="Descripción"
                        placeholder="Introduce una descripción para tu evento"
                    />
                </div>

            </div>
        </div>
    );
}
