import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import jwt_decode from "jwt-decode";
import { getUserToken } from "./utils";
import { getClubInfoservice } from "./clubs";

// Logins factory
const existingLogins = [
    {
        id: 1,
        name: 'Admin',
        email: 'admin@pitagorinesgroup.com',
        password: 'admin',
        role: 'Admin'
    },
    {
        id: 2,
        name: 'User',
        email: 'user@pitagorinesgroup.com',
        password: 'user',
        role: 'User'
    }
];

const authFactory = credentials => {
    const userExists = existingLogins.filter(existingLogin => existingLogin.email === credentials.email)[0];

    if (userExists) {
        return {
            ...userExists,
            token: "123"
        }
    } else {
        throw "Wrong credentials";
    }
}

export const loginService = async ({ email, password }) => {
    try {
        let response = await customFetch(`${endpoints.users}/login`, {
            method: "POST",
            bodyReq: { email, password },
        });

        if (response.error) throw response;

        const { id } = await getUserIdService(response.token);
        const decodedToken = jwt_decode(response.token);
        let userInfo;
        if (decodedToken.role === "Club") {
            userInfo = await getClubInfoservice(id, response.token);
            userInfo = {
                ...userInfo,
                id,
                email,
                role: "Club",
                token: response.token
            }
        }
        else {
            userInfo = await getUserInfoService(id, response.token);
            userInfo = {
                ...userInfo?.features,
                image: userInfo?.imgUser,
                id,
                email,
                role: "Admin",
                token: response.token
            }
        }

        return userInfo;
    } catch (err) {
        console.log('err', err);
        throw err;
    }
};

export const registerService = async (credentials) => {
    return authFactory(credentials); // Remove this line when integrating with back-end

    try {
        let response = await customFetch(`${endpoints.user}/register`, {
            method: "POST",
            bodyReq: credentials,
        });

        if (response.error) throw response;

        const { id } = await getUserIdService(response.token);
        const userInfo = await getUserInfoService(id, response.token);

        return { ...userInfo, token: response.token }
    } catch (err) {
        throw err;
    }
};

export const recoverPasswordService = async ({ newPassword, resetPasswordToken }) => {
    try {
        let response = await customFetch(`${endpoints.users}/resetPassword`, {
            method: "POST",
            bodyReq: { newPassword, resetPasswordToken },
            token: resetPasswordToken,
        });

        if (response.error) throw response;
    } catch (err) {
        throw err;
    }
};

export const resetPasswordService = async ({ email }) => {
    try {
        let response = await customFetch(`${endpoints.users}/urlToResetPassword/${email}`, {
            method: "GET",
        });

        if (response.error) throw response;
    } catch (err) {
        throw err;
    }
};

export const getUserIdService = async (token) => {
    try {
        let response = await customFetch(`${endpoints.users}/me`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }

};

export const getUserInfoService = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.user}/${id}`, {
            method: "GET",
            token: getUserToken() || token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const changeUserInfoService = async (id, data) => {
    try {
        let response = await customFetch(`${endpoints.user}/${id}`, {
            method: "PATCH",
            bodyReq: data,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
