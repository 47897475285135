import React, { useState, useEffect } from 'react'
import './CheckInComponent.scss';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';
import { IoTicketOutline } from 'react-icons/io5';
import { RiRefund2Line } from 'react-icons/ri';
import ConfirmationModal from 'components/elements/ConfirmationModal/ConfirmationModal';
import Button from 'components/elements/Button/Button';
import Logo from 'assets/icons/logo.png'

const CheckInComponent = (props) => {
    const { ticketId, loading, checkinInfos, refoundBooking, refundStatus } = props;
    const [entranceReservado, setEntranceReservado] = useState(null);
    const [entranceGeneral, setEntranceGeneral] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState()

    useEffect(() => {
        if (checkinInfos) {
            let reservado = 0, general = 0;

            checkinInfos.ticketsData.forEach((ticket) => {
                ticket.ticketId === ticketId && setSelectedUser({
                    checkIn: ticket.checkIn,
                    name: ticket.userData.name,
                    lastName: ticket.userData.lastName,
                    imgUser: ticket.userData.imgUser,
                })
                if (ticket.ticketInfo.type === 'palco') reservado += 1;
                if (ticket.ticketInfo.type === 'general') general += 1;
            })

            setEntranceGeneral(general);
            setEntranceReservado(reservado);
        }

    }, [checkinInfos])

    const confirmRefound = () => {
        refoundBooking()
        setConfirmationModal(false)
    }

    const ticketErrorsMessage = () => {
        if (!checkinInfos?.isValid && !checkinInfos?.wasPaid) return 'Error: Boleto inválido y no pagado'
        if (!checkinInfos?.isValid) return 'Error: Boleto inválido'
        if (!checkinInfos?.wasPaid) return 'Error, no se ha realizado el pago'
        return 'Se ha producido un error'
    }

    return (
        <div className="ckeckIn-wrapper">
            {!loading ? <>
                <div className="icon-wrapper" style={checkinInfos?.checkIn ? {} : { color: '#EB5757' }}>
                    {checkinInfos?.checkIn ? <IoIosCheckmarkCircleOutline /> : <IoIosCloseCircleOutline />}
                </div>
                <div className="message-wrapper">
                    <h2>
                        {checkinInfos?.checkIn ? "Se ha realizado el check-in" : "No se pudo realizar el check-in"}
                    </h2>
                </div>
                <div className="message-wrapper">
                    {checkinInfos?.checkIn ? `ID: ${ticketId}` : (
                        `${ticketErrorsMessage()}`
                    )}
                </div>

                <div className="selected-user-wrapper">
                    <div className="row-align">
                        {selectedUser?.imgUser && <div className="user-image-wrapper">
                            <img src={selectedUser.imgUser} alt="user" />
                        </div>}
                        <h5>{selectedUser.name}</h5>
                    </div>
                    <div className="row-align" style={checkinInfos?.checkIn ? { color: '#6FCF97' } : { color: '#EB5757' }}>
                        <div className="pagado-icon-wrapper">
                            {checkinInfos?.checkIn ? <IoIosCheckmarkCircleOutline /> : <IoIosCloseCircleOutline />}
                        </div>
                        <div>Check-in</div>
                    </div>
                </div>

                <div className="entrance-wrapper">
                    <h4>
                        Entradas
                    </h4>
                    <div className="entrance-details-wrapper">
                        <div className="entrance-icon" >
                            {entranceGeneral > 0 && <IoTicketOutline />}
                            {entranceReservado > 0 && <IoTicketOutline />}
                        </div>
                        <div>
                            {entranceGeneral > 0 && <h5>
                                {`${entranceGeneral} Entrada${entranceGeneral >= 1 ? 's' : null} General`}
                            </h5>}
                            {entranceReservado > 0 && <h5>
                                {`${entranceReservado} Entrada${entranceReservado >= 1 ? 's' : null} Reservado`}
                            </h5>}
                        </div>
                    </div>
                    <hr />
                    <div className="entrance-details-wrapper">
                        <div className="entrance-info-details">
                            <h5>
                                Total: <span style={{ fontWeight: 300 }}>{`${checkinInfos?.ReservationInfos.totalPrice}€`}</span>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="users-wrapper">
                    {checkinInfos?.ticketsData?.map((ticket) => {
                        return (
                            <div className="user-status-wrapper">
                                {selectedUser?.imgUser &&  <div className="user-image-wrapper">
                                    <img src={ticket.userData.imgUser} alt="user" />
                                </div>}
                                <h5>{ticket.userData.name}</h5>
                                <div className="price-status-wrapper" style={ticket.isPaid ? {} : { color: '#FE5833' }}>
                                    {`${ticket.ticketInfo.price}€`}
                                </div>
                                <div className="price-status-wrapper" style={ticket.isPaid ? {} : { color: '#FE5833' }}>
                                    {`${ticket.isPaid ? 'Pagado' : 'No pagado'}`}
                                </div>
                                <div className="pagado-icon-wrapper" style={ticket.checkIn ? {} : { color: '#FE5833' }}>
                                    {ticket?.checkIn ? <IoIosCheckmarkCircleOutline /> : <IoIosCloseCircleOutline />}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="button-wrapper">
                    {refundStatus === null ? <Button onClick={() => setConfirmationModal(true)}>
                        <img src={require('assets/icons/ArrowUpLeft.png')} alt="discoBall" className="back-icon" />
                        Reembolsar reserva
                    </Button> : refundStatus === "done" ?
                        <div className="success">Pedido reembolsado!</div> :
                        <div className="error">{refundStatus}</div>
                    }
                </div>
            </> : <div className="loading-spin-wrapper">
                <div className="spin-wrapper" />
            </div>
            }
            <div className="base-icon-wrapper">
                <img src={Logo} alt="Logo" />
            </div>
            <ConfirmationModal
                open={confirmationModal}
                close={() => setConfirmationModal(false)}
                confirmButton={() => confirmRefound()}
                denyButton={() => setConfirmationModal(false)}
                confirmText="Reembolsar"
                icon={<RiRefund2Line color="#fff" />}
                title="Reembolsar reserva?"
                subtitle={`¿Está seguro que quiere realizar el reembolso de esta entrada? Recuerde que esta operación es irreversible.`}
            />
        </div >
    )
}
export default CheckInComponent;