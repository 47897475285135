import React, { useState, useEffect } from 'react';
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useFormik } from "formik";
import Input from "components/elements/Input/Input";
import Button from "components/elements/Button/Button";
import { FiEdit3 } from 'react-icons/fi';
import ImagePicker from "components/elements/ImagePicker/ImagePicker";
import * as Yup from "yup";
import './ProfileComponent.scss';
import ConfirmationModal from 'components/elements/ConfirmationModal/ConfirmationModal';
import Logo from 'assets/icons/logo.png'

const EditProfileComponent = (props) => {
    const { submit, loading, history, user } = props;
    const { location } = user;
    const [firstLogin, setFirstLogin] = useState(user?.firstLogin);

    const editMode = window.location.pathname.includes('edit-profile');

    const userphoto = user.image;

    const formik = useFormik({
        initialValues: {
            image: "",
            name: user.name,
            description: user.description,
            direction: location.address,
            zone: location.zone,
            city: location.city,
            zipCode: location.postalCode,
            latitude: location.latitude,
            longitude: location.longitude,
            maxPeople: user.maxPeople,
        },
        validationSchema: editMode && Yup.object().shape({
            image: !userphoto && Yup.object().required("Obligatorio"),
            name: Yup.string().required("Obligatorio"),
            description: Yup.string().max(250, 'máximo de 250 caracteres').required("Obligatorio"),
            direction: Yup.string().required("Obligatorio"),
            zone: Yup.string().required("Obligatorio"),
            city: Yup.string().required("Obligatorio"),
            zipCode: Yup.string().required("Obligatorio"),
            latitude: Yup.string().required("Obligatorio"),
            longitude: Yup.string().required("Obligatorio"),
            maxPeople: Yup.string().required("Obligatorio")
        }),
        onSubmit: (values) => {
            submit(values);
        },
    });

    return (
        <DashboardLayout showBack={editMode} handleBack={() => {
            formik.resetForm();
            history.push(`/club/${user.id}/my-club/my-profile`)
        }} title={!editMode ? (
            <div className='editProfile-title-wrapper'>
                <h3>Mi perfil</h3>
                <FiEdit3 onClick={() => history.push(`/club/${user.id}/my-club/edit-profile`)} />
            </div>
        ) : (<h3>Editar mi perfil</h3>)
        }>
            <div className="edit-profile-wrapper">
                <div className="inputs-wrapper">
                    <div className="input-wrapper">
                        <ImagePicker
                            id="image"
                            formik={formik}
                            disabled={!editMode}
                            image={userphoto}
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="name"
                            formik={formik}
                            type="text"
                            label="Nombre"
                            placeholder="Nombre"
                            disabled={!editMode}
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="zone"
                            formik={formik}
                            type="text"
                            label="Zona"
                            placeholder="Zona"
                            disabled={!editMode}
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="direction"
                            formik={formik}
                            type="text"
                            label="Dirección"
                            placeholder="Dirección"
                            disabled={!editMode}
                        />
                    </div>
                    <div className="row">
                        <div className="input-wrapper">
                            <Input
                                id="city"
                                formik={formik}
                                type="text"
                                label="Ciudad"
                                placeholder="Ciudad"
                                disabled={!editMode}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Input
                                id="zipCode"
                                formik={formik}
                                type="text"
                                label="Código Postal"
                                placeholder="Código Postal"
                                disabled={!editMode}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="input-wrapper">
                            <Input
                                id="latitude"
                                formik={formik}
                                type="text"
                                label="Latitud"
                                placeholder="Latitud"
                                disabled={!editMode}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Input
                                id="longitude"
                                formik={formik}
                                type="text"
                                label="Longitud"
                                placeholder="Longitud"
                                disabled={!editMode}
                            />
                        </div>
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="maxPeople"
                            formik={formik}
                            type="text"
                            label="Aforo máximo"
                            placeholder="Aforo máximo"
                            disabled={!editMode}
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="description"
                            formik={formik}
                            type="textarea"
                            label="Descripción"
                            placeholder="Descripción"
                            disabled={!editMode}
                        />
                    </div>
                    {editMode && (
                        <Button
                            loading={loading}
                            type="submit"
                            onClick={() => formik.handleSubmit()}>
                            Guardar cambios
                        </Button>
                    )}
                </div>
                
                <ConfirmationModal
                    centerButton
                    open={firstLogin}
                    denyButton={() => setFirstLogin(false)}
                    title="¡Bienvenido a We Club!"
                    subtitle="La plataforma para la gestión integral de tu discoteca.
                    Por favor, a continuación, completa y revisa que los datos de tu perfil son correctos.
                   
                   Si tienes cualquier cuestión no dudes en contactarnos.
                   Muchas gracias,
                    Equipo de We Club."
                    icon={Logo}
                    confirmText="Aceptar"
                    confirmButton={() => setFirstLogin(false)}
                />
            </div>
        </DashboardLayout>
    )
}
export default EditProfileComponent;