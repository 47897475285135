import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getStagesService = async (palcoId) => {
    try {
        let response = await customFetch(`${endpoints.palcos}?filter={"where":{"and":[{"palcoId": "${palcoId}"}]}}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const getStagesDetailsService = async (idClub) => {
    try {
        let response = await customFetch(`${endpoints.palcos}/${idClub}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const deletePalcoService = async (idPalco) => {
    try {
        let response = await customFetch(`${endpoints.palcos}/${idPalco}`, {
            method: "DELETE",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const patchPalcoService = async (values, idPalco) => {
    try {
        let response = await customFetch(`${endpoints.palcos}/${idPalco}`, {
            method: "PATCH",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}
