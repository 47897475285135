import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getBookingService = async (idClub) => {
    try {
        let response = await customFetch(`${endpoints.bookings}?filter={"where":{"and":[{"idClub": "${idClub}"}]}}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const getBookingDetailsService = async (idBooking) => {
    try {
        let response = await customFetch(`${endpoints.bookings}/${idBooking}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const getCheckinDetailsService = async (idBooking, ticketId) => {
    try {
        let response = await customFetch(`${endpoints.bookings}/${idBooking}/checkin/${ticketId}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}