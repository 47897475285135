import React from 'react';
import './DetailButton.scss';

const DetailButton = (props) => {
    const { onClick, children } = props;
    return (
        <button className="details-button-wrapper" onClick={onClick}>
            {children}
        </button>
    );
}

export default DetailButton;