import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationTriangle, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import "./ToastrContent.scss"

const getToastrType = type => {
    switch (type) {
        case 'success':
            return faCheck;
        case 'danger':
            return faTimesCircle;
        case 'warning':
            return faExclamationTriangle;
        case 'info':
            return faInfoCircle;
        default:
            return faCheck;
    }
}

const ToastrContent = props => (
    <div className={`toastr-content-wrapper ${props.type}`}>
        <div className="toastr-icon">
            <FontAwesomeIcon className="icon" icon={getToastrType(props.type)} />
        </div>
        <div className="toastr-content">
            <h4>{props.title}</h4>
            <p>{props.message}</p>
        </div>
    </div >
);

export default ToastrContent