import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader'
import Table from 'components/elements/Table/Table'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import React, { useEffect, useState } from 'react'
import './GeneralEntranceComponent.scss'
import Moment from 'moment'
import DetailButton from 'components/elements/DetailButton/DetailButton'
import { euroFormat } from 'services/utils'
import NoInfo from 'components/elements/NoInfo/NoInfo'
import { sortByDecreasingDate } from 'components/Utils/sortDate'
import Checked from 'components/Utils/CheckedComponent/checkedComponent'

import Input from 'components/elements/Input/Input'

const GeneralEntranceComponent = (props) => {
    const { history, bookings, user } = props
    const [loadingData, setLoadingData] = useState(true)

    const [data, setData] = useState([])

    const [searchTerm, setSearchTerm] = useState('')

    const columns = [
        {
            title: 'Día de la compra'.toUpperCase(),
            dataIndex: 'buyDate',
            key: 'buyDate',
            width: 150,
            sorter: (a, b) => a.buyDate.localeCompare(b.buyDate),
        },
        {
            title: 'Día de la reserva'.toUpperCase(),
            dataIndex: 'eventDate',
            key: 'eventDate',
            width: 150,
            sorter: (a, b) => a.eventDate.localeCompare(b.eventDate),
        },
        {
            title: 'Evento'.toUpperCase(),
            dataIndex: 'event',
            key: 'event',
            width: 350,
            sorter: (a, b) => a.event.localeCompare(b.event),
        },
        {
            title: 'N°reserva'.toUpperCase(),
            dataIndex: 'reservationNumber',
            key: 'reservationNumber',
            width: 200,
            sorter: (a, b) => a.reservationNumber - b.reservationNumber,
        },
        {
            title: 'Nombre'.toUpperCase(),
            dataIndex: 'name',
            key: 'name',
            width: 300,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'N°Entradas'.toUpperCase(),
            dataIndex: 'entrance',
            key: 'entrance',
            width: 70,
            sorter: (a, b) => a.entrance - b.entrance,
        },
        {
            title: 'importe'.toUpperCase(),
            dataIndex: 'price',
            key: 'price',
            width: 140,
            sorter: (a, b) => a.price - b.price,
        },
        {
            title: 'Acción'.toUpperCase(),
            dataIndex: 'action',
            key: 'action',
        },
    ]

    useEffect(() => {
        if (bookings) {
            const dataAux = bookings
                .map((booking) => ({
                    buyDate: Moment(booking.createdAt).format('LLL') || '-',
                    eventDate: booking.bookingDate ? Moment(booking.bookingDate).format('DD/MM/YY') : '-',
                    event: booking?.eventInfo?.headline || '-',
                    reservationNumber: booking.bookingNumber || '-',
                    name: booking.ownerInfo?.name || '-',
                    entrance: booking.tickets.length || 0,
                    price: euroFormat(booking.totalPrice) || '-',
                    // checkin: booking.checkIn ? <Checked /> : null,
                    action: (
                        <DetailButton
                            onClick={() =>
                                history.push(`/club/${user.id}/booking/general-entrance/details/${booking.bookingId}`)
                            }
                        >
                            Ver Detalles
                        </DetailButton>
                    ),

                    isActive: !!booking.ownerInfo,
                }))
                .filter((booking) => {
                    if (searchTerm === '') return booking.isActive

                    return (
                        booking.isActive &&
                        (booking.event.toLowerCase().includes(searchTerm) ||
                            booking.name.toLowerCase().includes(searchTerm))
                    )
                })

            const sortedByDate = sortByDecreasingDate(dataAux, 'buyDate', 'DD/MM/YY')

            if (sortedByDate.length === 0) setData(null)
            else setData([...sortedByDate])

            setLoadingData(false)
        }
    }, [bookings, history, searchTerm, user.id])

    return (
        <DashboardLayout
            title={
                <Input
                    type="text"
                    placeholder="Buscar"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                />
            }
        >
            <div className="header-wrapper">
                <h3>Reservas</h3>
                <h4>Entrada general</h4>
            </div>
            <div className="general-entrance-wrapper">
                {loadingData || data?.length > 0 ? <Table columns={columns} data={data} /> : <NoInfo />}
            </div>
        </DashboardLayout>
    )
}

export default GeneralEntranceComponent
