import React, { useState } from "react";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import { Formik, Form } from "formik";
import * as yup from "yup";
import './RecoveryPasswordComponents.scss';

const RecoveryPasswordComponents = props => {
    const { error, submit, loading, history } = props;

    return (
        <AuthLayout>
            <div className="register-component">
                <h2>Recuperar cuenta</h2>
                <p>
                    ¿Ha perdido su contraseña? Introduzca su dirección de correo electrónico. Recibirá un enlace para crear una nueva contraseña por correo electrónico.
                </p>
                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={yup.object().shape({
                        email: yup
                            .string()
                            .required("Campo obligatorio"),
                    })}
                    onSubmit={(values) => submit(values)}>
                    {(formik) => (
                        <>
                            <Form>
                                <Input
                                    id="email"
                                    formik={formik}
                                    type="text"
                                    label="Email"
                                    placeholder="Email"
                                />

                                {
                                    error ?
                                        <div className="error-message-global">{error}</div> :
                                        null
                                }

                                <Button
                                    loading={loading}
                                    type="submit"
                                    onClick={formik.handleSubmit}>
                                    Enviar email
                                </Button>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </AuthLayout>
    );
}

export default RecoveryPasswordComponents;
