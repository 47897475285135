import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import InputComponent from 'components/elements/Input/Input'
import ImagePicker from 'components/elements/ImagePicker/ImagePicker'
import ReactModal from 'components/elements/ReactModal/ReactModal'
import ButtonComponent from 'components/elements/Button/Button'
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useMemo } from 'react'
import { MdClose } from 'react-icons/md'

const ModalPromotionsInfo = (props) => {
    const { close, open = false, promotion, submit, edit, view, loading, isEditing } = props

    const promoPhoto = promotion?.image?.attachment
    const promoType = promotion?.image?.attachmentType

    const formik = useFormik({
        initialValues: {
            code: promotion?.code || '',
            key: promotion?.key || '',
            name: promotion?.couponName || '',
            maxUsage: promotion?.maxUsage || '',
            discount: promotion?.discount || '',
            dateStart: promotion?.dateStart ? moment(promotion?.dateStart) : '',
            dateEnd: promotion?.dateEnd ? moment(promotion?.dateEnd) : '',
            file: { path: promoPhoto || '', type: promoType || '' },
            description: promotion?.text || '',
        },
        validationSchema: Yup.object().shape({
            code: Yup.string().required('Obrigatorio'),
            name: Yup.string()
                .required('Obrigatorio')
                .when('code', (code, schema) => {
                    return schema.test({
                        test: (name) => {
                            if (!isEditing) return true
                            return name !== promotion?.couponName ? code !== promotion?.code : true
                        },
                        message: 'es necesario cambiar el campo "Código".',
                    })
                }),
            maxUsage: Yup.number()
                .required('Obrigatorio')
                .typeError('Ingresa solo numeros')
                .min(0, 'usa solo numeros positivos')
                .when('code', (code, schema) => {
                    return schema.test({
                        test: (maxUsage) => {
                            if (!isEditing) return true
                            return maxUsage !== promotion?.maxUsage ? code !== promotion?.code : true
                        },
                        message: 'es necesario cambiar el campo "Código".',
                    })
                }),
            discount: Yup.number()
                .required('Obrigatorio')
                .typeError('Ingresa solo numeros')
                .min(0, 'usa solo numeros positivos')
                .max(100, 'valor no válido')
                .when('code', (code, schema) => {
                    return schema.test({
                        test: (discount) => {
                            if (!isEditing) return true
                            return discount !== promotion?.discount ? code !== promotion?.code : true
                        },
                        message: 'es necesario cambiar el campo "Código".',
                    })
                }),
            dateStart: Yup.string().required('Obligatorio'),
            dateEnd: Yup.string()
                .nullable()
                .when('dateStart', (dateStart, schema) => {
                    return schema.test({
                        test: (dateEnd) => {
                            return !dateEnd || !moment(dateEnd).isBefore(dateStart, 'day')
                        },
                        message: 'La fecha de finalización debe ser posterior a la fecha de inicio.',
                    })
                }),
            file: Yup.object(),
            description: Yup.string().max(250, 'máximo de 250 caracteres').required('Obligatorio'),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            !promotion ? submit(values) : edit(values)
            close()
            formik.resetForm()
        },
    })

    const showVideo = useMemo(() => {
        return (
            formik?.values?.file?.type?.toLowerCase() === 'video' ||
            promotion?.image?.attachmentType?.toLowerCase() === 'video'
        )
    }, [formik.values])

    return (
        <div className="modal-promotions-info-wrapper">
            <ReactModal
                open={open}
                close={() => {
                    close()
                    formik.resetForm()
                }}
                header={
                    <ContainerHeader
                        title={view ? 'Publicación' : isEditing ? 'Editar publicación' : 'Crear publicación'}
                        subtitle={
                            isEditing
                                ? 'solo es posible cambiar "Nombre del cupón", "Usar máximo" y "Descuento" si se cambia el código'
                                : ''
                        }
                        onClick={() => {
                            close()
                            formik.resetForm()
                        }}
                        modal
                    />
                }
                footer={
                    !view ? (
                        <div className="modal-button-wrapper">
                            <ButtonComponent
                                loading={loading}
                                type="submit"
                                onClick={() => {
                                    formik.handleSubmit()
                                }}
                            >
                                Publicar
                            </ButtonComponent>
                        </div>
                    ) : (
                        <></>
                    )
                }
            >
                <div className="promotions-wrapper" style={{ padding: '0' }}>
                    {showVideo && formik?.values?.file?.path ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                marginTop: '20px',
                            }}
                        >
                            <iframe width="540" height="280" src={formik?.values?.file?.path} />
                            {!view && (
                                <div
                                    className="resetVideoAttachment"
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '40px',
                                        borderRadius: '100px',
                                        border: '2px solid #FE5833',
                                        backgroundColor: '#000',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: '999',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        formik.setFieldValue('file', { path: '' })
                                    }}
                                >
                                    <MdClose size={20} color="#FE5833" />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="modal-components__image">
                            <div style={{ marginTop: '20px' }}>
                                <ImagePicker id="file" formik={formik} editedComponent disabled={view} />
                            </div>
                            {!view && (formik?.values?.file?.path || formik?.values?.file?.base64) && (
                                <div className="close-icon" onClick={() => formik.setFieldValue('file', { path: '' })}>
                                    <AiFillCloseCircle size={20} />
                                </div>
                            )}
                        </div>
                    )}

                    {isEditing && (
                        <p style={{ padding: '10px 30px', maxWidth: '600px', color: '#fff', margin: 0 }}>
                            solo es posible cambiar "Nombre del cupon", "Uso máximo" y "Descuento" si se cambia el
                            código
                        </p>
                    )}

                    <div className="modal-components">
                        <InputComponent id="code" formik={formik} type={'text'} label="Código" disabled={view} />
                    </div>
                    <div className="modal-components">
                        <InputComponent
                            id="name"
                            formik={formik}
                            type={'text'}
                            label="Nombre del cupon"
                            disabled={view}
                        />
                    </div>
                    <div className="modal-components__dates">
                        <InputComponent
                            id="maxUsage"
                            formik={formik}
                            type={'text'}
                            label="Uso máximo"
                            disabled={view}
                        />

                        <InputComponent
                            id="discount"
                            formik={formik}
                            type={'text'}
                            label="Descuento (%)"
                            disabled={view}
                        />
                    </div>

                    <div className="modal-components__dates">
                        <InputComponent
                            id="dateStart"
                            type="date"
                            formik={formik}
                            label="Fecha inicio"
                            placeholder="Fecha inicio"
                            disabled={view}
                        />
                        <InputComponent
                            id="dateEnd"
                            type="date"
                            formik={formik}
                            label="Fecha final"
                            placeholder="Fecha final"
                            disabled={view}
                        />
                    </div>
                    <div className="modal-components">
                        <InputComponent
                            id="description"
                            formik={formik}
                            type={view ? 'text' : 'textarea'}
                            label="Texto"
                            disabled={view}
                        />
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}
export default ModalPromotionsInfo
