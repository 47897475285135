import React, { useEffect, useState } from 'react';
import { getBookingDetailsService } from 'services/bookingService';
import GeneralEntranceDetailComponents from '../components/GeneralEntranceDetailComponents';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { updateUser } from 'redux/actions/userActions';
import { toast } from 'react-toastify';
import ToastrContent from 'components/elements/Toastr/Toastr';

const GeneralEntranceDetail = (props) => {
    const { history, user } = props;
    const [loading, setLoading] = useState(false);
    const [bookingDetails, setBookingDetails] = useState([]);
    const [requestFail, setRequestFail] = useState();

    let { idBooking } = useParams();

    useEffect(() => {
        getbookingDetails();
    }, [])

    const getbookingDetails = async () => {
        try {
            setLoading(true);
            const res = await getBookingDetailsService(idBooking);
            
            setBookingDetails(res);
        }
        catch (e) {
            console.log('error setting preferences', e);
            setRequestFail(true)
            toast(<ToastrContent type='danger' title={'Error'} message={'Inténtalo más tarde'} />, {
            progressClassName: 'toastr-progress-bar danger',
            })
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <GeneralEntranceDetailComponents
            {...props}
            {...{ history, user, bookingDetails, requestFail }}
        />
    );
}

const mapStateToProps = (store) => ({
user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralEntranceDetail))