import React, { useState, useEffect } from 'react'
import Button from 'components/elements/Button/Button';
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader';
import Input from 'components/elements/Input/Input';
import ReactModal from 'components/elements/ReactModal/ReactModal';
import * as Yup from "yup";
import { useFormik } from 'formik';
import './ModalProducts.scss';
import ImagePicker from 'components/elements/ImagePicker/ImagePicker';

const ModalProducts = (props) => {
    const { open, close, loading, submit, product, type, edit, categories } = props;
    const viewMode = type === 'Detail';
    const editMode = type === 'Edit';

    const productPhoto = product?.image;

    const categoryOptions = categories?.map((category) => ({
        value: category.categoryId,
        label: category.name
    }))

    const selectedCategory = categoryOptions?.filter((category) => category.label === product?.category?.name)[0]

    const isAvailableOptions = [
        {
            value: true,
            label: 'Disponible'
        },
        {
            value: false,
            label: 'No disponible'
        }
    ]

    const isAvailable = isAvailableOptions.filter((op) => op.value === product?.isAvailable)[0]

    const formik = useFormik({
        initialValues: {
            image: productPhoto || "",
            category: viewMode ? selectedCategory.label : selectedCategory || "",
            name: product?.name || "",
            price: product?.price || "",
            available: viewMode ? isAvailable.label : isAvailable || isAvailableOptions[0],
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            image: !productPhoto ? Yup.object().required("Obligatorio") : Yup.string(),
            category: Yup.object().required("Obligatorio"),
            name: Yup.string().required("Obligatorio"),
            price: Yup.number().required("Obligatorio"),
            available: Yup.object().required("Obligatorio"),
        }),
        onSubmit: (values) => {
            !editMode ?
                submit(values)
                : edit(values, product.productId)
            close();
        }
    });

    return (
        <ReactModal
            open={open}
            close={() => {
                close();
                formik.resetForm()
            }}
            header={<ContainerHeader
                title={editMode ? 'Guardar cambios' : 'Añadir producto'}
                onClick={() => {
                    close();
                    formik.resetForm()
                }}
                modal
            />}
            footer={!viewMode &&
                <Button
                    loading={loading}
                    type="submit"
                    onClick={() => formik.handleSubmit()}>
                    {editMode ? 'Guardar cambios' : 'Añadir producto'}
                </Button>
            }
        >
            <div className="modal-products-wrapper">
                <div className="inputs-wrapper">
                    <div className="input-wrapper">
                        <ImagePicker
                            id="image"
                            formik={formik}
                            subtitle="Imagen del producto"
                            center
                            disabled={viewMode}
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="category"
                            formik={formik}
                            type={viewMode ? "text" : "select"}
                            label="Categoría"
                            placeholder="Categoría"
                            options={categoryOptions}
                            disabled={viewMode}
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="name"
                            formik={formik}
                            type="text"
                            label="Nombre del producto"
                            placeholder="Nombre"
                            disabled={viewMode}
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="price"
                            formik={formik}
                            type="text"
                            label="Añadir precio"
                            placeholder="precio"
                            disabled={viewMode}
                        />
                    </div>
                    <div className="input-wrapper">
                        <Input
                            id="available"
                            formik={formik}
                            type={viewMode ? "text" : "select"}
                            label="Disponibilidad del producto"
                            placeholder="Disponibilidad"
                            options={isAvailableOptions}
                            disabled={viewMode}
                        />
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}
export default ModalProducts;

