import React, { useState, useEffect } from 'react'
import ZonesComponent from '../components/ZonesComponent'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUser } from 'redux/actions/userActions';
import { deleteZoneService, getZonesService, postZoneService } from 'services/zonesService';
import { getClubInfoservice, patchClubService } from 'services/clubs';
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';

const ZonesContainer = (props) => {
    const { user, history, updateUser } = props;
    const [loading, setLoading] = useState(false);
    const [zones, setZones] = useState([]);

    useEffect(() => {
        getZones();
    }, [])

    const getZones = async () => {
        try {
            setLoading(true);
            const res = await getZonesService(user.id);

            setZones(res);
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
            setLoading(false);
        }
    }

    const submitImage = async (image, id) => {
        try {
            setLoading(true);
            const data = {
                mapImage: image.base64
            }

            await patchClubService(id, data)
            toast(<ToastrContent type="success" title={"Éxito"} message={"la imagen ha sido actualizado."} />, {
                progressClassName: "toastr-progress-bar success",
            });
            updateUser({ ...user, mapImage: image.base64 })
        }
        catch (e) {
            console.log('error setting preferences', e);
            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde"} />, {
                progressClassName: "toastr-progress-bar danger",
            })
        }
        finally {
            setLoading(false);
        }
    }

    const submitZone = async (values) => {
        try {
            setLoading(true);
            const res = await postZoneService(values);
        }
        catch (e) {
            console.log('error setting preferences', e);
            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde"} />, {
                progressClassName: "toastr-progress-bar danger",
            })
        }
        finally {
            setLoading(false);
        }
    }

    const remove = async (id) => {
        try {
            setLoading(true);
            const res = await deleteZoneService(id);

            getZones();

            toast(<ToastrContent type="success" title={"Éxito"} message={"la zona ha sido eliminada"} />, {
                progressClassName: "toastr-progress-bar success",
            })
        }
        catch (e) {
            console.log('error setting preferences', e);
            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde"} />, {
                progressClassName: "toastr-progress-bar danger",
            })
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <ZonesComponent
            {...props}
            {...{ zones, loading, user, history, submitImage, remove }}
        />
    )
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZonesContainer))