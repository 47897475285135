import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getProductsService = async (idClub) => {
    try {
        let response = await customFetch(`${endpoints.products}?filter={"where":{"and":[{"idClub": "${idClub}"}]}}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const postProductService = async (values) => {
    try {
        let response = await customFetch(`${endpoints.products}`, {
            method: "POST",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw (e)
    }
}

export const deleteProductService = async (idClub) => {
    try {
        let response = await customFetch(`${endpoints.products}/${idClub}`, {
            method: "DELETE",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw (e)
    }
}

export const patchProductService = async (values, idClub) => {
    try {
        let response = await customFetch(`${endpoints.products}/${idClub}`, {
            method: "PATCH",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw (e)
    }
}