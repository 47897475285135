import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getEventsInfoService = async (idClub) => {
    try {
        let response = await customFetch(`${endpoints.events}?filter={"where":{"and":[{"idClub": "${idClub}"}]}}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const feedEventsService = async () => {
    try {
        let response = await customFetch(endpoints.feed, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const postEventService = async (values) => {
    try {
        let response = await customFetch(endpoints.events, {
            method: "POST",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const deleteEventService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.events}/${id}`, {
            method: "DELETE",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const patchEventService = async (values, id) => {
    
    try {
        let response = await customFetch(`${endpoints.events}/${id}`, {
            method: "PATCH",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}
