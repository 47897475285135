import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import InputComponent from 'components/elements/Input/Input'
import ImagePicker from 'components/elements/ImagePicker/ImagePicker'
import ReactModal from 'components/elements/ReactModal/ReactModal'
import ButtonComponent from 'components/elements/Button/Button'
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader'
import { useMemo } from 'react'
import { MdClose } from 'react-icons/md'

const ModalPublicationsInfo = (props) => {
    const { close, open, publication, submit, edit, view, loading } = props

    const postPhoto = publication?.attachments[0]?.attachment
    const postType = publication?.attachments[0]?.attachmentType
    const formik = useFormik({
        initialValues: {
            key: publication?.id || '',
            date: moment(publication?.date).format('L') || '',
            file: { path: postPhoto || '', attachmentType: postType || '' },
            description: publication?.content || '',
        },
        validationSchema: Yup.object().shape({
            file: Yup.object(),
            description: Yup.string().max(250, 'máximo de 250 caracteres').required('Obligatorio'),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            !publication ? submit(values) : edit(values)
            close()
            formik.resetForm()
        },
    })

    const showVideo = useMemo(() => {
        return (
            formik?.values?.file?.type?.toLowerCase() === 'video' ||
            publication?.attachments[0]?.attachmentType?.toLowerCase() === 'video'
        )
    }, [formik.values])

    return (
        <div className="modal-publications-info-wrapper">
            <ReactModal
                open={open}
                close={() => {
                    close()
                    formik.resetForm()
                }}
                header={
                    <ContainerHeader
                        title={view ? 'Publicación' : 'Crear publicación'}
                        onClick={() => {
                            close()
                            formik.resetForm()
                        }}
                        modal
                    />
                }
                footer={
                    !view ? (
                        <div className="modal-button-wrapper">
                            <ButtonComponent loading={loading} type="submit" onClick={formik.handleSubmit}>
                                Publicar
                            </ButtonComponent>
                        </div>
                    ) : (
                        <></>
                    )
                }
            >
                <div className="publications-wrapper" style={{ padding: '0' }}>
                    {showVideo && formik?.values?.file?.path ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                        >
                            <iframe width="500" height="280" src={formik?.values?.file?.path} />
                            {!view && (
                                <div
                                    className="resetVideoAttachment"
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '40px',
                                        borderRadius: '100px',
                                        border: '2px solid #FE5833',
                                        backgroundColor: '#000',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: '999',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        formik.setFieldValue('file', { path: '' })
                                    }}
                                >
                                    <MdClose size={20} color="#FE5833" />
                                </div>
                            )}
                        </div>
                    ) : (
                        <ImagePicker id="file" formik={formik} editedComponent disabled={view} />
                    )}
                    <div className="modal-components">
                        <InputComponent
                            id="description"
                            formik={formik}
                            type={view ? 'text' : 'textarea'}
                            label="Texto"
                            disabled={view}
                        />
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}
export default ModalPublicationsInfo
