import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserId, getUserToken } from "./utils";

export const postRefoundBooking = async (chargeId) => {
    try {
        let response = await customFetch(`${endpoints.userPurchases}/refund/${chargeId}`, {
            method: "POST",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const getBookingRefundChargeId = async (ticketId) => {
    const userId = getUserId();

    try {
        let response = await customFetch(`${endpoints.userPurchases}/refundId/${userId}/${ticketId}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}