import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getNotificationsInfoservice = async (clubId) => {
    try {
        let response = await customFetch(`${endpoints.notifications}?filter={"where":{"and":[{"clubId": "${clubId}"}]}}`, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw e;
    }
}

export const postNotificationsService = async ({ body, title, userId, date }) => {
    try {

        const users = await customFetch(`${endpoints.users}`, {
            method: "GET",
            token: getUserToken(),
        });

        users?.length && users.forEach((user) => {
            const userId = user?.IAM?.idUser;
            if (userId) {
                customFetch(`${endpoints.notifications}`, {
                    method: "POST",
                    bodyReq: { body, title, userId, date },
                    token: getUserToken(),
                })
            }
        })


        return true;

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const postNotificationService = async (values) => {
    try {
        let response = await customFetch(`${endpoints.notifications}/all-users`, {
            method: 'POST',
            bodyReq: values,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw e;
    }
}
