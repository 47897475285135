import React, { useState, useEffect, useMemo } from 'react'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import NoInfo from 'components/elements/NoInfo/NoInfo'
import './PromotionsComponent.scss'
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader'
import ModalPromotionsInfo from '../ModalPromotionsInfo/ModalPromotionsInfo'
import { FiEdit3 } from 'react-icons/fi'
import { HiOutlineTrash } from 'react-icons/hi'
import DetailButton from 'components/elements/DetailButton/DetailButton'
import moment from 'moment'
import Placeholder from 'assets/images/placeholder.png'
import Table from 'components/elements/Table/Table'
import ConfirmationModal from 'components/elements/ConfirmationModal/ConfirmationModal'
import ContactInfo from 'components/elements/Table/ContactInfo/ContactInfo'

const PromotionsComponent = (props) => {
    const { submit, promotions, edit, remove } = props
    const [modalType, setModalType] = useState('')
    const [selectedPromotion, setSelectedPromotion] = useState()
    const noData = useMemo(() => {
        if (promotions.length > 0) return false
        return true
    }, [promotions])

    const columns = [
        {
            title: 'CÓDIGO'.toUpperCase(),
            dataIndex: 'code',
            key: 'code',
            width: 150,
        },
        {
            title: 'IMAGEN'.toUpperCase(),
            dataIndex: 'image',
            key: 'image',
            width: 150,
        },
        {
            title: 'FECHA INICIO'.toUpperCase(),
            dataIndex: 'dateStart',
            key: 'dateStart',
            width: 200,
        },
        {
            title: 'FECHA FIN'.toUpperCase(),
            dataIndex: 'dateEnd',
            key: 'dateEnd',
            width: 200,
        },
        {
            title: 'TEXTO'.toUpperCase(),
            dataIndex: 'text',
            key: 'text',
        },
        {
            title: 'Acción'.toUpperCase(),
            dataIndex: 'Accion',
            key: 'Accion',
            width: 200,
        },
    ]

    const promotionSelect = (type, code) => {
        const selectedPromotion = promotions.filter((promotion) => {
            return promotion.code === code
        })[0]

        if (selectedPromotion) setSelectedPromotion(selectedPromotion)
        setModalType(type)
    }

    const data = useMemo(
        () =>
            promotions.map((promotion) => {
                return {
                    key: promotion.id,
                    code: promotion.code,
                    dateStart: moment(promotion.dateStart).format('DD/MM/YYYY'),
                    dateEnd: moment(promotion.dateEnd).format('DD/MM/YYYY'),
                    image: (
                        <ContactInfo
                            video={promotion?.image?.attachmentType.toLowerCase() === 'video'}
                            image={
                                promotion?.image?.attachmentType.toLowerCase() === 'video'
                                    ? Placeholder
                                    : promotion?.image?.attachment
                                    ? promotion?.image?.attachment
                                    : Placeholder
                            }
                            contactKey={promotion.id}
                        />
                    ),
                    text: promotion.text,
                    Accion: <Actions promotionSelect={promotionSelect} promotionId={promotion.code} />,
                }
            }),
        [promotions]
    )

    const handleRemove = (id) => remove(id)

    return (
        <DashboardLayout>
            <div className="promotions-wrapper">
                <ContainerHeader title="Promociones" button="Crear promoción" onClick={() => setModalType('CREATE')} />
                {noData ? (
                    <NoInfo img={require('assets/icons/Megaphone.png')} text="Aún no has realizado una promoción" />
                ) : (
                    <Table data={data} columns={columns} />
                )}
            </div>

            <ModalPromotionsInfo
                open={['CREATE', 'VIEW', 'EDIT'].includes(modalType)}
                close={() => {
                    setModalType(null)
                    setSelectedPromotion(null)
                }}
                view={modalType === 'VIEW'}
                isEditing={modalType === 'EDIT'}
                edit={edit}
                submit={submit}
                loading={false}
                promotion={selectedPromotion}
            />

            <ConfirmationModal
                open={modalType === 'DELETE'}
                close={() => {
                    setModalType(null)
                    setSelectedPromotion(null)
                }}
                confirmButton={() => {
                    handleRemove(selectedPromotion.id)
                    setModalType(null)
                    setSelectedPromotion(null)
                }}
                denyButton={() => setModalType(null)}
                confirmText="Eliminar"
                icon={<HiOutlineTrash />}
                title="¿Eliminar promocion?"
                subtitle={`estás a punto de eliminar la promocion del codigo ${selectedPromotion?.code}`}
                loading={false}
            />
        </DashboardLayout>
    )
}

const Actions = (props) => {
    const { promotionSelect, promotionId } = props
    return (
        <div className="action-buttons-wrapper">
            <div
                onClick={() => {
                    promotionSelect('EDIT', promotionId)
                }}
            >
                <FiEdit3 />
            </div>
            <div
                onClick={() => {
                    promotionSelect('DELETE', promotionId)
                }}
            >
                <HiOutlineTrash />
            </div>
            <DetailButton
                onClick={() => {
                    promotionSelect('VIEW', promotionId)
                }}
            >
                Ver Detalles
            </DetailButton>
        </div>
    )
}

export default PromotionsComponent
