import React from 'react'
import { TimePicker as AintTimePicker, Space } from 'antd'
import './TimePicker.scss'

const TimePicker = (props) => {
    const { placeholder, style, value, onChange, onBlur, className } = props

    //Return an array of numbers from start to end
    function range(start, end) {
        const result = []
        for (let i = start; i < end; i++) {
            result.push(i)
        }
        return result
    }

    return (
        <div className="TimePicker-wrapper">
            <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <AintTimePicker
                    className={`ranger-wrapper ${className}`}
                    style={style}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    format="HH:mm"
                />
            </Space>
        </div>
    )
}

export default TimePicker
