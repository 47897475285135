import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import React from 'react';
import Moment from 'moment';
import Table from 'components/elements/Table/Table';
import './GeneralEntranceDetailComponents.scss';
import PaidOutButton from 'components/elements/PaidOutButton/PaidOutButton';
import ContactInfo from 'components/elements/Table/ContactInfo/ContactInfo';
import NoInfo from 'components/elements/NoInfo/NoInfo';
import { euroFormat } from 'services/utils';
import Checked from 'components/Utils/CheckedComponent/checkedComponent';

const GeneralEntranceDetailComponents = (props) => {
    const { history, user, bookingDetails, requestFail } = props;
    const detailMode = window.location.pathname.includes('details');

    const columns = [
        {
            title: 'nombre'.toUpperCase(),
            dataIndex: 'name',
            key: 'name',
            width: 570,
        },
        {
            title: 'n°ticket'.toUpperCase(),
            dataIndex: 'ticket',
            key: 'ticket',
            width: 290,
        },
        {
            title: 'importe'.toUpperCase(),
            dataIndex: 'value',
            key: 'value',
            width: 290,
        },
        {
            title: 'estado'.toUpperCase(),
            dataIndex: 'state',
            key: 'state',
            width: 140,
        },
        {
            title: 'check-in'.toUpperCase(),
            dataIndex: 'checkin',
            key: 'checkin',
            width: 120,
        },
        {
            title: 'fecha de pago'.toUpperCase(),
            dataIndex: 'data',
            key: 'data',
            width: 160,
        },
    ]

    const dataIndex = bookingDetails?.tickets?.map((ticket) => {
        return ({
            name: <ContactInfo image={ticket.userInfo?.imgUser} name={ticket.userInfo?.name} key={ticket.idUser} />,
            ticket: ticket.ticketNumber || '-',
            value: euroFormat(ticket?.ticketInfo?.price) || '-',
            state: <State show={ticket.isPaid} />,
            checkin: ticket.checkIn ? <Checked /> : null,
            data: ticket.paymentDate ? Moment(ticket.paymentDate).format('L') : "-",
        })
    });

    return (
        <DashboardLayout
            showBack={detailMode}
            handleBack={() => history.push(`/club/${user.id}/booking/general-entrance`)}
            title={<h3>Detalle reserva N°{bookingDetails?.bookingNumber ? bookingDetails?.bookingNumber : ' -'} </h3>}
        >
            <div className="general-entrance-detail-wrapper">
                {requestFail ? (
                    <div className="request-fail-wrapper">
                        <NoInfo text="datos no encontrados" />
                    </div>
                ) : (
                    <div>
                        <Table data={dataIndex} columns={columns} />
                        <div className="total-payment-wrapper">
                            total: {bookingDetails.totalPrice}€
                        </div>
                    </div>
                )}
            </div>
        </DashboardLayout>
    );
}

export default GeneralEntranceDetailComponents;

const State = ({ show }) => {
    if (show) return <PaidOutButton>Pagado</PaidOutButton>;
    return <></>;
}