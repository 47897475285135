import React, { useEffect, useState } from 'react';
import { getStagesService } from 'services/stageService';
import StageComponent from '../components/StageComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUser } from 'redux/actions/userActions';
import { getBookingService, getCheckinDetailsService } from 'services/bookingService';

const Stage = (props) => {
    const { history, user } = props;
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState(false);

    useEffect(() => {
        getBooking();
    }, [])

    const getBooking = async () => {
        try {
            const res = await getBookingService(user.idClub);

            if (res) {
                const palcoBookings = []
                res.forEach((booking) => {
                    if (booking.tickets[0]?.ticketInfo?.type === 'palco') palcoBookings.push(booking)
                })
                
                setBookings(palcoBookings);
            }
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
    }
    return (
        <StageComponent
            {...props}
            {...{ history, user, loading, bookings }}
        />
    );
}

const mapStateToProps = (store) => ({
user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stage))