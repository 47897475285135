const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.dev.weclub.pitagorinesgroup.com/' //DEV
// const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.pre.weclub.pitagorinesgroup.com/' //PRE

export const endpoints = {
    user: BASE_URL + 'user',
    users: BASE_URL + 'users',
    userSettings: BASE_URL + 'user-settings',
    clubs: BASE_URL + 'clubs',
    notifications: BASE_URL + 'notifications',
    events: BASE_URL + 'events',
    feed: BASE_URL + 'feed',
    zones: BASE_URL + 'zones',
    bookings: BASE_URL + 'bookings',
    palcos: BASE_URL + 'palcos',
    products: BASE_URL + 'products',
    posts: BASE_URL + 'posts',
    categories: BASE_URL + 'categories',
    ticketTypes: BASE_URL + 'ticket-types',
    postsSocialNetwork: BASE_URL + 'posts-social-network',
    attachments: BASE_URL + 'attachments',
    userPurchases: BASE_URL + 'user-purchases',
    coupon: BASE_URL + 'coupon',
}
