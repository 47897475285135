import { store } from "redux/store";

export function getUserToken() {
    const state = store.getState();
    const token = state?.userReducer?.user?.token;
    return token;
}

export function getUserId() {
    const state = store.getState();
    const id = state?.userReducer?.user?.id;
    return id;
}

export function euroFormat(value) {
        return (value).toLocaleString('es-es',{style: 'currency', currency: 'EUR'})
}