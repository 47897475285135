import React, { useState, useEffect } from 'react'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import PaidOutButton from 'components/elements/PaidOutButton/PaidOutButton';
import moment from 'moment';
import ContactInfo from 'components/elements/Table/ContactInfo/ContactInfo';
import Table from 'components/elements/Table/Table';
import './StageDetailsComponent.scss';
import _ from 'lodash';
import NoInfo from 'components/elements/NoInfo/NoInfo';
import { euroFormat } from 'services/utils';
import Checked from 'components/Utils/CheckedComponent/checkedComponent';

const StageDetailsComponent = (props) => {
    const { history, user, booking, bookingId, loading, requestFail } = props;
    const detailMode = window.location.pathname.includes('details');
    const [data, setData] = useState([]);
    const [productsData, setProductsData] = useState([]);

    const columns = [
        {
            title: 'nombre'.toUpperCase(),
            dataIndex: 'name',
            key: 'name',
            width: 480,
        },
        {
            title: 'n°ticket'.toUpperCase(),
            dataIndex: 'ticket',
            key: 'ticket',
            width: 300,
        },
        {
            title: 'importe a pagar'.toUpperCase(),
            dataIndex: 'toPay',
            key: 'toPay',
            width: 300,
        },
        {
            title: 'estado'.toUpperCase(),
            dataIndex: 'state',
            key: 'state',
            width: 140,
        },
        {
            title: 'check-in'.toUpperCase(),
            dataIndex: 'checkin',
            key: 'checkin',
            width: 120,
        },
        {
            title: 'fecha de pago'.toUpperCase(),
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            width: 170,
        },
    ]

    useEffect(() => {
        if (booking && booking.tickets) {
            const auxData = booking.tickets.map((ticket) => ({
                name: <ContactInfo image={ticket.userInfo?.imgUser} name={ticket.userInfo?.name} key={ticket.ticketId} />,
                ticket: ticket.ticketNumber,
                toPay: euroFormat(ticket.ticketInfo.price),
                state: ticket.isPaid ? <PaidOutButton>Pagado</PaidOutButton> : null,
                checkin: ticket.checkIn ? <Checked /> : null,
                paymentDate: ticket.paymentDate ? moment(ticket.paymentDate).format('L') : '-',
            }))

            const products = _.groupBy(booking.products, 'productId');
            const productsArray = _.values(products);
            const auxProductsData = productsArray.map((product) => ({
                amont: product.length,
                product: product[0].name,
                price: euroFormat(product[0].price),
                total: euroFormat(product.length * product[0].price),
            }))

            setProductsData(auxProductsData);
            setData(auxData);
        }
    }, [booking])

    const resumeColumns = [
        {
            title: 'cantidad'.toUpperCase(),
            dataIndex: 'amont',
            key: 'amont',
            width: 470,
        },
        {
            title: 'producto'.toUpperCase(),
            dataIndex: 'product',
            key: 'product',
            width: 470,
        },
        {
            title: 'preccio'.toUpperCase(),
            dataIndex: 'price',
            key: 'price',
            width: 430,
        },
        {
            title: 'importe'.toUpperCase(),
            dataIndex: 'total',
            key: 'total',
            width: 170,
        },
    ]

    return (
        <DashboardLayout
            showBack={detailMode}
            handleBack={() => history.push(`/club/${user.id}/booking/stage`)}
            title={<h3>Detalle reserva Nº{booking?.bookingNumber ? booking?.bookingNumber : ' -'} </h3>}
        >
            <div className="stage-details-wrapper">
                {requestFail ? (
                    <div className="request-fail-wrapper">
                        <NoInfo text="datos no encontrados" />
                    </div>
                ) : (
                    <div>
                        <Table data={data} columns={columns} />
                        <h3>resumen del pedido</h3>

                        <Table data={productsData} columns={resumeColumns} />
                        <div className="total-payment-wrapper">
                            total: {booking?.totalPrice}€
                        </div>
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}
export default StageDetailsComponent;