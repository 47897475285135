import React, { useState, useEffect } from 'react'
import './NoInfo.scss'

const NoInfo = (props) => {
    const { text, img } = props
    return (
        <div className="no-info-wrapper">
            <img src={img ? img : require('assets/icons/discoBall.png')} alt="discoBall" />
            <h3>{text || 'Aún no has añadido'}</h3>
        </div>
    )
}
export default NoInfo
