import moment from 'moment';

export const sortByDecreasingDate = (data, type, format) => {
    const sortedByDate = data.sort((a, b) => {
        return moment(b[type]) - moment(a[type]) 
    })
    
    sortedByDate.forEach((item) => item[type] = moment(item[type]).format(format))

    return sortedByDate;
}