import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getClubsInfoservice = async () => {
    try {
        let response = await customFetch(`${endpoints.clubs}`, {
            method: "GET",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const getClubInfoservice = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.clubs}/${id}`, {
            method: "GET",
            token: token || getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const postClubRegister = async (values) => {
    try {
        let response = await customFetch(`${endpoints.clubs}/register`, {
            method: "POST",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}


export const patchClubService = async (id, values) => {
    try {
        let response = await customFetch(`${endpoints.clubs}/${id}`, {
            method: "PATCH",
            bodyReq: values,
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const deleteClubService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.clubs}/${id}`, {
            method: "DELETE",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const suspentClubService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.clubs}/suspend/${id}`, {
            method: "PATCH",
            token: getUserToken(),
        })

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}
