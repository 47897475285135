import React, { useEffect, useState } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import './EventsComponent.scss';
import ContainerHeader from "components/elements/ContainerHeader/ContainerHeader";
import Table from "components/elements/Table/Table";
import "antd/dist/antd.css";
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import moment from 'moment';
import ContactInfo from "components/elements/Table/ContactInfo/ContactInfo";
import ModalEvents from "./ModalInfos/ModalEvents";
import { euroFormat } from 'services/utils';
import { sortByDecreasingDate } from "components/Utils/sortDate";
import NoInfo from "components/elements/NoInfo/NoInfo";

const EventsComponents = (props) => {
    const { loading, events, submit, deleteClub, editClub } = props;
    const [modalProps, setModalProps] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'Nombre'.toUpperCase(),
            dataIndex: 'Nombre',
            key: 'Nombre',
            width: 480,
        },
        {
            title: 'Fecha'.toUpperCase(),
            dataIndex: 'Fecha',
            key: 'Fecha',
            width: 220,
        },
        {
            title: 'Hora'.toUpperCase(),
            dataIndex: 'Hora',
            key: 'Hora',
            width: 220,
        },
        {
            title: 'Aforo'.toUpperCase(),
            dataIndex: 'Aforo',
            key: 'Aforo',
            width: 220,
        },
        {
            title: 'Precio'.toUpperCase(),
            dataIndex: 'Precio',
            key: 'Precio',
            width: 220,
        },
        {
            title: 'Acción'.toUpperCase(),
            dataIndex: 'Accion',
            key: 'Accion',
            width: 80,
        },
    ];

    function handleEditType(type, eventId) {
        const selectedEvent = events.filter((event) => event.eventId === eventId)[0]
        setModalProps({
            type: type,
            event: selectedEvent
        });
        setOpenModal(true);
    }

    function handleDelete(eventId, ticketId) {
        deleteClub(eventId, ticketId)
        setOpenModal(false);
    }

    function handleSubmit(values) {
        submit(values);
        setOpenModal(false);
    }

    function handleEdit(values, eventId, ticketId) {
        editClub(values, eventId, ticketId);
        setOpenModal(false);
    }

    useEffect(() => {
        if (events && events.length > 0) {
            const formattedData = events.map((event) => {
                return ({
                    key: event.eventId,
                    Nombre: <ContactInfo image={event.image} name={event.headline} key={event.eventId} />,
                    Fecha: moment(event.startingDate).format('L'),
                    Hora: moment(event.startingDate).format('HH:mm'),
                    Aforo: event.maxPeople || "-",
                    Precio: euroFormat(event.ticketTypeInfo?.ticketPrice || 10),
                    Accion: <Actions
                        editClick={() => handleEditType('Edit', event.eventId)}
                        deleteClick={() => handleEditType('Delete', event.eventId)}
                    />,
                })
            });

            const sortedByDate = sortByDecreasingDate(formattedData, 'Fecha', 'DD/MM/YY')

            setData(sortedByDate);
        } else setData(null)
    }, [events])

    return (
        <DashboardLayout>
            <div className="events-wrapper">
                <ContainerHeader title='Eventos' button="Añadir evento" onClick={() => {
                    handleEditType('Create');
                    setOpenModal(true);
                }} />
                {data?.length > 0 ?
                    <Table
                        data={data}
                        columns={columns}
                    />
                    : <NoInfo 
                        text="Ningun evento"
                    />
                }
                <ModalEvents
                    openModal={openModal}
                    loading={loading}
                    handleDelete={handleDelete}
                    events={events}
                    modalProps={modalProps}
                    handleSubmit={handleSubmit}
                    handleEdit={handleEdit}
                />
            </div>
        </DashboardLayout>
    );
}

const Actions = ({ editClick, deleteClick }) => {
    return (
        <div className="action-buttons-wrapper">
            <div
                onClick={editClick}
            ><FiEdit3 /></div>
            <div
                onClick={deleteClick}
            ><HiOutlineTrash /></div>
        </div>
    );
}

export default EventsComponents;
