import React from 'react'
import { DatePicker as AintDatePicker, Space } from 'antd'
import './DatePicker.scss'
import moment from 'moment'

const DatePicker = (props) => {
    const { placeholder, style, value, onChange, onBlur, className, disabled } = props

    function disabledDate(current) {
        return current && current < moment().subtract(1, 'days').endOf('day')
    }

    return (
        <div className="DatePicker-wrapper">
            <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <AintDatePicker
                    style={style}
                    placeholder={placeholder}
                    className={`ranger-wrapper ${className}`}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabledDate={disabledDate}
                    format="DD/MM/YYYY"
                    disabled={disabled}
                />
            </Space>
        </div>
    )
}

export default DatePicker
