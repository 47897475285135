import React, { useState } from 'react'
import './Input.scss'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { compressImage, reactSelectStyles } from './utils'
import DatePicker from '../Calendar/DatePicker/DatePicker'
import TimePicker from '../Calendar/TimePicker/TimePicker'
import Select from 'react-select'
import moment from 'moment'
import { endpoints } from 'services/endpoints'
import { getUserToken } from 'services/utils'
import { reject, resolve } from 'lodash'

const InputComponent = (props) => {
    const {
        type,
        label,
        id,
        className,
        formik,
        placeholder,
        formats,
        disabled,
        value,
        options,
        defaultValue,
        cancelTouchedverification,
        onChange,
    } = props
    let formikProps = {}

    const [showPassword, setShowPassword] = useState(false)

    if (formik) {
        formikProps = {
            value: formik.values[id],
            onBlur: () => formik.setFieldTouched(id),
            onChange: (e) => formik.setFieldValue(id, e.target.value),
            error: !cancelTouchedverification ? formik.touched[id] && formik.errors[id] : formik.errors[id],
        }
    } else {
        formikProps = {
            value: value,
            onChange: onChange,
        }
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0]

        const nameParts = file?.name?.split('.')
        let extension = nameParts[nameParts?.length - 1]

        if (extension === 'mp4') uploadFileService(file)
        else encodeImageFileAsURL(file)
    }

    const uploadFileService = async (file) => {
        var formdata = new FormData()
        formdata.append('', file, file.name)

        var myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${getUserToken()}`)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
        }

        await fetch(endpoints.attachments, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const response = JSON.parse(result)
                submitFile(response)
                if (response.error) reject(response)
                resolve(response)
            })
            .catch((error) => reject(error))
    }

    const encodeImageFileAsURL = async (file) => {
        if (!file) return
        var reader = new FileReader()
        reader.onload = async function () {
            let fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000), //kb
                base64: reader.result,
                file: file,
            }
            const nameParts = file.name.split('.')
            let extension = nameParts[nameParts.length - 1]
            fileInfo.type = extension
            if (formats && !formats.includes(extension)) {
                alert(`El archivo no es valido. Por favor adjuntar un archivo ${formats.join(', ')}`)
                return
            }
            if (file.type?.includes('image')) {
                const downscaledImage = await compressImage(reader.result)
                fileInfo.base64 = downscaledImage
                fileInfo.type = extension
            }
            submitFile(fileInfo)
        }
        reader.readAsDataURL(file)
    }

    const submitFile = (file) => {
        formik.setFieldValue(id, file)
    }

    return (
        <div className={`input-wrapper ${formikProps.error ? 'error' : ''}`}>
            <label htmlFor={id}>{label}</label>
            {(type === 'text' || type === 'password') && (
                <>
                    <input
                        {...props}
                        type={showPassword || type}
                        id={id}
                        name={id}
                        className={`input ${className} ${formikProps.error ? 'error' : ''}`}
                        onBlur={formikProps.onBlur}
                        onChange={formikProps.onChange}
                        value={formikProps.value || value}
                        placeholder={placeholder}
                    />
                    {type === 'password' && <PasswordIcon {...{ showPassword, setShowPassword }} />}
                </>
            )}
            {type === 'textarea' && (
                <>
                    <textarea
                        {...props}
                        id={id}
                        name={id}
                        className={`input ${className} ${formikProps.error ? 'error' : ''}`}
                        onBlur={formikProps.onBlur}
                        onChange={formikProps.onChange}
                        value={formikProps.value}
                        placeholder={placeholder}
                    />
                </>
            )}
            {type === 'date' && (
                <DatePicker
                    {...props}
                    id={id}
                    name={id}
                    className={`input ${className} ${formikProps.error ? 'error' : ''}`}
                    onBlur={formikProps.onBlur}
                    onChange={(data) => {
                        formik.setFieldValue(id, data)
                    }}
                    value={formik.values[id]}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            )}
            {type === 'time' && (
                <TimePicker
                    {...props}
                    id={id}
                    name={id}
                    className={`input ${className} ${formikProps.error ? 'error' : ''}`}
                    onBlur={formikProps.onBlur}
                    onChange={(data) => {
                        const utcTime = moment(moment(data).valueOf()).utc()
                        if (data) formik.setFieldValue(id, utcTime)
                        else formik.setFieldValue(id, '')
                    }}
                    value={formik.values[id] && moment(formik.values[id]).local()}
                    placeholder={placeholder}
                />
            )}
            {type === 'file' && (
                <input
                    style={{
                        width: '0.1px',
                        height: '0.1px',
                        opacity: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        zIndex: -1,
                    }}
                    type="file"
                    disabled={disabled}
                    name={id}
                    accept="image/png, image/jpeg, image/jpg, video/mp4"
                    id={id}
                    onChange={(event) => handleImageUpload(event)}
                />
            )}
            {type === 'select' && (
                <Select
                    className="custom-react-select"
                    defaultValue={defaultValue}
                    name={id}
                    styles={reactSelectStyles}
                    value={formikProps.value}
                    onChange={(e) => formik.setFieldValue(id, e)}
                    options={options}
                    ariaLiveMessages={{}}
                />
            )}
            {formikProps.error && <span className="error-message">{formikProps.error}</span>}
        </div>
    )
}

const PasswordIcon = ({ showPassword, setShowPassword }) => {
    const openEye = <AiOutlineEye />
    const closeEye = <AiOutlineEyeInvisible />

    return (
        <div className="password-icon" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? openEye : closeEye}
        </div>
    )
}

export default InputComponent
