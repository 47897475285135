import React, { useCallback, useEffect, useState } from 'react'
import { getNotificationsInfoservice, postNotificationService, postNotificationsService } from 'services/notifications'
import NotificationsComponent from '../components/NotificationsComponent'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateUser } from 'redux/actions/userActions'
import { toast } from 'react-toastify'
import ToastrContent from 'components/elements/Toastr/Toastr'
import moment from 'moment'
import { postUserSettingsService } from 'services/userSettingsService'

const Notifications = (props) => {
    const { user, updateUser } = props
    const [loading, setLoading] = useState(false)
    const [notifications, setNotifications] = useState()

    function failToast() {
        return toast(<ToastrContent type="danger" title={'Error'} message={'Inténtalo más tarde'} />, {
            progressClassName: 'toastr-progress-bar danger',
        })
    }

    const getNotifications = useCallback(async () => {
        try {
            const res = await getNotificationsInfoservice(user.id)

            if (res) {
                setNotifications(res)
            }
        } catch (e) {
            console.log(e)
        }
    }, [])

    useEffect(() => {
        getNotifications()
    }, [getNotifications])

    const submit = async (value) => {
        try {
            setLoading(true)

            const valuesAdjust = {
                body: value.description,
                title: value.subject,
                clubId: user.id,
                date: moment().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                type: 'General',
            }

            await postNotificationService(valuesAdjust)

            toast(<ToastrContent type="success" title={'Éxito'} message={'La notificación ha sido creada'} />, {
                progressClassName: 'toastr-progress-bar success',
            })

            getNotifications()
        } catch (e) {
            console.log(e)
            failToast()
        } finally {
            setLoading(false)
        }
    }

    return <NotificationsComponent {...props} {...{ submit, loading, notifications }} />
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications))
