import React from 'react';
import Check from 'assets/icons/check.png'
import './checkedComponent.scss';

const Checked = () => {
    return (
        <div className="checkin-wrapper">
            <img src={Check} alt="checked" />
        </div>
    )
}

export default Checked;