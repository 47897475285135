import React, { useState, useEffect } from 'react'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import './EditZonesComponent.scss'
import { FiEdit3 } from 'react-icons/fi'
import Button from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import ImagePicker from 'components/elements/ImagePicker/ImagePicker'
import { HiPlusSm, HiMinusSm } from 'react-icons/hi'
import _ from 'lodash'
import { toast } from 'react-toastify'
import ToastrContent from 'components/elements/Toastr/Toastr'

const EditZonesComponent = (props) => {
    const { history, user, create, zone, loading, edit } = props
    const editMode = window.location.pathname.includes('zone/')
    const createMode = window.location.pathname.includes('create-zones')
    const editing = window.location.pathname.includes('edit-zone/')

    const [validPalcos, setValidPalcos] = useState()
    const [initialValues, setInitialValues] = useState({
        image: { path: '' },
        name: '',
        palcos: [
            {
                name_palco: '',
                price_palco: '',
                maxPeople_palco: '',
                id_palco: '',
            },
        ],
    })

    useEffect(() => {
        restoreZoneValue()
    }, [zone])

    const zonePhoto = zone?.image || ''

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            image: zonePhoto ? null : Yup.object().required('Obligatorio'),
            name: Yup.string().required('Obligatorio'),
        }),
        onSubmit: (values) => {
            validPalcos
                ? createMode
                    ? create(values)
                    : edit(values)
                : toast(<ToastrContent type="danger" title={'Error'} message={'crie um reservado'} />, {
                      progressClassName: 'toastr-progress-bar danger',
                  })
        },
    })

    const restoreZoneValue = () => {
        let initialData = { palcos: [] }

        if (zone) {
            initialData['image'] = zone.image
            initialData['name'] = zone.name
        }
        zone?.palcos &&
            zone.palcos.forEach((palco) => {
                initialData.palcos.push({
                    name_palco: palco.name,
                    price_palco: palco.price,
                    maxPeople_palco: palco.capacity,
                    id_palco: palco.palcoId,
                })
            })

        initialData.palcos.length < 1 &&
            initialData.palcos.push({
                name_palco: '',
                price_palco: '',
                maxPeople_palco: '',
                id_palco: '',
            })

        setInitialValues(initialData)
    }

    function handleHistory() {
        if (createMode) return history.push(`/club/${user.id}/my-club/zones`)
        else return history.push(`/club/${user.id}/my-club/zones`)
    }

    const valueCheck = () => {
        let data = { palcos: [] }

        data['image'] = formik.values.image
        data['name'] = formik.values.name

        formik.values.palcos.length &&
            formik.values.palcos.forEach((palco) => {
                if (palco.name_palco !== '') {
                    setValidPalcos(true)
                    data.palcos.push({
                        name_palco: palco.name_palco,
                        price_palco: palco.price_palco,
                        maxPeople_palco: palco.maxPeople_palco,
                        id_palco: palco.id_palco,
                    })
                } else {
                    setValidPalcos(false)
                    data.palcos.push({
                        name_palco: '',
                        price_palco: '',
                        maxPeople_palco: '',
                        id_palco: '',
                    })
                }
            })

        setInitialValues(data)
    }

    const addPalco = () => {
        const initialValuesCopy = _.cloneDeep(formik.values)
        initialValuesCopy.palcos.push({
            name_palco: '',
            price_palco: '',
            maxPeople_palco: '',
            id_palco: '',
        })

        setInitialValues(initialValuesCopy)
    }

    const removePalco = (indexToRemove) => {
        const initialValuesCopy = _.cloneDeep(formik.values)

        initialValuesCopy.palcos.splice(indexToRemove, 1)

        setInitialValues(initialValuesCopy)
    }

    const returnToEditMode = () => {
        restoreZoneValue()
        history.push(`/club/${user.id}/my-club/zone/${zone.zoneId}`)
    }

    return (
        <DashboardLayout
            showBack={true}
            handleBack={() => {
                formik.resetForm()
                handleHistory()
                editing ? returnToEditMode() : history.push(`/club/${user.id}/my-club/zones`)
            }}
            title={
                editMode ? (
                    <div className="editProfile-title-wrapper">
                        <h3>{zone?.name || '-'}</h3>
                        {!editing && (
                            <FiEdit3
                                onClick={() => history.push(`/club/${user.id}/my-club/edit-zone/${zone.zoneId}`)}
                            />
                        )}
                    </div>
                ) : (
                    <h3>Añadir zona</h3>
                )
            }
        >
            <div className="edit-zones-wrapper">
                <div className="image-picker">
                    <ImagePicker
                        id="image"
                        formik={formik}
                        disabled={createMode ? false : !editing}
                        image={zonePhoto}
                        cancelTouchedverification={true}
                    />
                </div>
                <h6>Imagen de la zona</h6>
                <div className="input-wrapper">
                    <Input
                        id="name"
                        formik={formik}
                        type="text"
                        label="Nombre de la zona"
                        placeholder="Nombre de la zona"
                        disabled={createMode ? false : !editing}
                        cancelTouchedverification={true}
                    />
                </div>
                {formik.values.palcos.map((x, index) => {
                    return (
                        <Palco disabled={createMode ? false : !editing} {...{ index, formik, addPalco, removePalco }} />
                    )
                })}

                {(!editMode || editing) && (
                    <Button
                        loading={loading}
                        type="submit"
                        onClick={() => {
                            valueCheck()
                            formik.handleSubmit()
                        }}
                    >
                        {editMode ? 'Guardar cambios' : 'Añadir zona'}
                    </Button>
                )}
            </div>
        </DashboardLayout>
    )
}

const Palco = (props) => {
    const { index, formik, disabled, addPalco, removePalco } = props

    return (
        <div className="reservation-wrapper">
            <div className="input-wrapper">
                <Input
                    id={`palcos.${index}.name_palco`}
                    value={formik.values.palcos[index].name_palco}
                    formik={formik}
                    type="text"
                    label="Nombre del reservado"
                    placeholder="Nombre del reservado"
                    disabled={disabled}
                />
            </div>
            <div className="input-wrapper">
                <Input
                    id={`palcos.${index}.price_palco`}
                    value={formik.values.palcos[index].price_palco}
                    formik={formik}
                    type="text"
                    label="Precio mínimo"
                    placeholder="Precio mínimo"
                    disabled={disabled}
                />
            </div>
            <div className="input-wrapper">
                <Input
                    id={`palcos.${index}.maxPeople_palco`}
                    value={formik.values.palcos[index].maxPeople_palco}
                    formik={formik}
                    type="text"
                    label="Aforo Máximo"
                    placeholder="Aforo Máximo"
                    disabled={disabled}
                />
            </div>
            {!disabled && (
                <div className="icons-reservation-wrapper">
                    {formik.values.palcos.length > 1 && (
                        <div className="icon-wrapper" onClick={() => !disabled && removePalco(index)}>
                            <HiMinusSm />
                        </div>
                    )}
                    <div className="icon-wrapper" onClick={() => !disabled && addPalco()}>
                        <HiPlusSm />
                    </div>
                </div>
            )}
        </div>
    )
}

export default EditZonesComponent
