import React from 'react'
import './PaidOutButton.scss';

const PaidOutButton = ({children}) => {
    return (
        <div className="paid-out-wrapper">
            {children}
        </div>
    );
}

export default PaidOutButton;