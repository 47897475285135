import React, { useEffect, useState } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import './ProductsComponent.scss';
import ContainerHeader from "components/elements/ContainerHeader/ContainerHeader";
import Table from "components/elements/Table/Table";
import "antd/dist/antd.css";
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import ContactInfo from "components/elements/Table/ContactInfo/ContactInfo";
import DetailButton from "components/elements/DetailButton/DetailButton";
import ConfirmationModal from "components/elements/ConfirmationModal/ConfirmationModal";
import AvailableButton from "components/elements/AvailableButton/AvailableButton";
import Placeholder from 'assets/images/placeholder.png'
import ModalProducts from "./ModalProducts/ModalProducts";
import NoInfo from "components/elements/NoInfo/NoInfo";

const ProductsComponent = (props) => {
    const { products, loading, submit, remove, edit, categories } = props;

    const [data, setData] = useState();
    const [showModal, setShowModal] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState({});

    const columns = [
        {
            title: 'CATEGORÍA'.toUpperCase(),
            dataIndex: 'category',
            key: 'category',
            width: 240,
        },
        {
            title: 'IMAGEN'.toUpperCase(),
            dataIndex: 'image',
            key: 'image',
            width: 220,
        },
        {
            title: 'PRODUCTO'.toUpperCase(),
            dataIndex: 'product',
            key: 'product',
            width: 430,
        },
        {
            title: 'DISPONIBILIDAD'.toUpperCase(),
            dataIndex: 'available',
            key: 'available',
            width: 260,
        },
        {
            title: 'Acción'.toUpperCase(),
            dataIndex: 'Accion',
            key: 'Accion',
            width: 80,
        },
    ];

    useEffect(() => {
        if (products) {
            const auxData = products.map((product) => ({
                key: product.productId,
                category: product.category?.name,
                image: <ContactInfo image={product.image || Placeholder} key={product.productId} />,
                product: product.name,
                available: <AvailableButton unavailable={!product.isAvailable}>{product.isAvailable ? 'Disponible' : 'No disponible'}</AvailableButton>,
                Accion: <Actions selectAction={selectAction} productId={product.productId} />,
            }))

            setData(auxData);
        } else setData(null)

    }, [products])

    function selectAction(type, productId) {
        const product = products.filter((p) => p.productId === productId)[0];
        if (product) setSelectedProduct(product)
        else setSelectedProduct(null)
        setShowModal(type);
    }

    return (
        <DashboardLayout>
            <div className="publications-wrapper">
                <ContainerHeader title='Productos' button="Añadir producto" onClick={() => selectAction('Create')} />

                {data?.length > 0 ?
                    <Table
                        data={data}
                        columns={columns}
                    /> : <NoInfo text="Ningun producto" />
                }
                <ModalProducts
                    open={["Create", "Edit", "Detail"].includes(showModal)}
                    close={() => setShowModal(null)}
                    product={selectedProduct}
                    loading={loading}
                    type={showModal}
                    submit={submit}
                    edit={edit}
                    categories={categories}
                />
                <ConfirmationModal
                    open={showModal === "Delete"}
                    icon={<HiOutlineTrash />}
                    title="¿Eliminar tipo de producto?"
                    subtitle={`estás a punto de eliminar el producto ${selectedProduct?.name}`}
                    confirmText="Eliminar"
                    loading={loading}
                    denyButton={() => setShowModal(null)}
                    confirmButton={() => {
                        remove(selectedProduct.productId);
                        setShowModal(null);
                    }}
                />
            </div>
        </DashboardLayout>
    );
}

const Actions = (props) => {
    const { selectAction, productId } = props;
    return (
        <div className="action-buttons-wrapper">
            <div onClick={() => selectAction('Edit', productId)}>
                <FiEdit3 />
            </div>
            <div onClick={() => selectAction('Delete', productId)}>
                <HiOutlineTrash />
            </div>
            <DetailButton onClick={() => selectAction('Detail', productId)}>
                Ver Detalles
            </DetailButton>
        </div>
    );
}

export default ProductsComponent;
