import React, { useCallback, useEffect, useState } from 'react';
import { getEventsInfoService, postEventService, deleteEventService, patchEventService } from 'services/events';
import EventsComponent from '../components/EventsComponent';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import moment from 'moment';
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';
import { deleteTicketTypeService, patchTicketTypeService, postTicketTypeService } from 'services/ticketType';

const Event = (props) => {
    const { user } = props;
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);

    const loadEvents = useCallback(async () => {
        try {
            const res = await getEventsInfoService(user.id);
            setEvents(res);
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
    }, [])

    useEffect(() => {
        loadEvents();
    }, [loadEvents])

    function failToast() {
        return (
            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde."} />, {
                progressClassName: "toastr-progress-bar danger",
            })
        )
    }

    function handleTime(value, time) {
        const timeObject = moment(time).toObject();
        return moment(value).set(
            {
                'hour': timeObject.hours,
                'minute': timeObject.minutes,
            }
        ).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]")
    }

    const submit = async (value) => {
        const adjustmentData = {
            idClub: user.id,
            description: value.description,
            image: value.image.base64,
            headline: value.headline,
            maxPeople: value.maxPeople,
            startingDate: handleTime(value.startingDate, value.startingTime),
            endDate: handleTime(value.endDate, value.endTime || value.startingTime),
        }

        if (value.endTime) {
            adjustmentData.endDate = handleTime(value.endDate, value.endTime)
        }

        try {
            setLoading(true);
            const res = await postEventService(adjustmentData);

            if (res) {
                const data = {
                    type: 'general',
                    idClub: user.id,
                    eventId: res.eventId,
                    description: value.description,
                    price: Number(value.price),
                    date: adjustmentData.startingDate,
                }
                
                await postTicketTypeService(data)
                
                toast(<ToastrContent type="success" title={"Éxito"} message={"El evento ha sido creado."} />, {
                    progressClassName: "toastr-progress-bar success",
                });
                
            }

            loadEvents();

        }
        catch (e) {
            console.log('error setting preferences', e);

            failToast()
        }
        finally {
            setLoading(false);
        }
    }

    const deleteClub = async (eventId, ticketId) => {
        try {
            setLoading(true);
            const res = await deleteEventService(eventId);

            if (res) {                    
                await deleteTicketTypeService(ticketId);

                toast(<ToastrContent type="success" title={"Éxito"} message={"El evento ha sido eliminado."} />, {
                    progressClassName: "toastr-progress-bar success",
                });
            }

            loadEvents();
        }
        catch (e) {
            console.log('error setting preferences', e);

            failToast()
        }
        finally {
            setLoading(false);
        }
    }

    const editClub = async (values, eventId, ticketId) => {
        const data = {
            eventId,
            idClub: user.id,
            headline: values.headline,
            description: values.description,
            maxPeople: values.maxPeople,
            startingDate: handleTime(values.startingDate, values.startingTime),
            endDate: handleTime(values.endDate, values.endTime),
        }

        if (values.image?.base64) data.image = values.image?.base64;

        try {
            setLoading(true);
            const res = await patchEventService(data, eventId);

            if (res) {
                const dataType = {
                    ticketTypeId: ticketId,
                    type: 'general',
                    idClub: user.id,
                    eventId: res.eventId,
                    description: values.description,
                    price: Number(values.price),
                    date: data.startingDate,
                }
                
                await patchTicketTypeService(dataType, ticketId)
                
                toast(<ToastrContent type="success" title={"Éxito"} message={"El evento ha sido actualizado."} />, {
                    progressClassName: "toastr-progress-bar success",
                });
            }

            loadEvents();
        }
        catch (e) {
            console.log('error setting preferences', e);

            failToast()
        }
        finally {
            setLoading(false);
        }
    }

    return <EventsComponent
        {...props}
        {...{ events, loading, submit, deleteClub, editClub }}
    />
}


const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Event))