import React, { useState, useEffect } from 'react';
import RecoveryPasswordComponents from "../components/RecoveryPasswordComponents";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import { resetPasswordService } from "services/user"
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';

const RecoveryPassword = (props) => {
    const { updateUser, history } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async (values) => {
        setLoading(true);

        const data = {
            email: values.email,
        }

        try {
            const response = await resetPasswordService(data);
            setLoading(false);

            history.push("/login");

            toast(<ToastrContent type="success" title={"Éxito"} message={"Revisa tu correo electrónico para completar la operación"} />, {
                progressClassName: "toastr-progress-bar success",
            });

        } catch (error) {
            setError(error.message);
            setLoading(false);

            toast(<ToastrContent type="danger" title={"Error"} message={"Inténtalo más tarde"} />, {
                progressClassName: "toastr-progress-bar danger",
            });
        }
    };

    return <RecoveryPasswordComponents
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        history={history}
    />;
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(RecoveryPassword));