import React, { useEffect, useState } from 'react'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import './PublicationsContainer.scss'
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader'
import Table from 'components/elements/Table/Table'
import 'antd/dist/antd.css'
import { HiOutlineTrash } from 'react-icons/hi'
import { FiEdit3 } from 'react-icons/fi'
import moment from 'moment'
import ContactInfo from 'components/elements/Table/ContactInfo/ContactInfo'
import DetailButton from 'components/elements/DetailButton/DetailButton'
import Placeholder from 'assets/images/placeholder.png'
import ModalPublicationsInfo from '../ModalPublicationsInfo/ModalPublicationsInfo'
import ConfirmationModal from 'components/elements/ConfirmationModal/ConfirmationModal'
import { sortByDecreasingDate } from 'components/Utils/sortDate'
import NoInfo from 'components/elements/NoInfo/NoInfo'

const PublicationsContainer = (props) => {
    const { posts, loading, submit, edit, remove } = props
    const [data, setData] = useState()
    const [modalType, setModalType] = useState('')
    const [selectedPost, setSelectedPost] = useState()

    const columns = [
        {
            title: 'FECHA PUBLICACIÓN'.toUpperCase(),
            dataIndex: 'date',
            key: 'date',
            width: 310,
        },
        {
            title: 'IMAGEN'.toUpperCase(),
            dataIndex: 'image',
            key: 'image',
            width: 200,
        },
        {
            title: 'TEXTO'.toUpperCase(),
            dataIndex: 'texto',
            key: 'texto',
            width: 650,
        },
        {
            title: 'Acción'.toUpperCase(),
            dataIndex: 'Accion',
            key: 'Accion',
            width: 50,
        },
    ]

    useEffect(() => {
        if (posts) {
            const auxData = []
            posts.forEach((post) => {
                auxData.push({
                    key: post.id,
                    date: moment(post.publishedDate).format('LLL'),
                    image: (
                        <ContactInfo
                            video={post?.attachments[0]?.attachmentType.toLowerCase() === 'video'}
                            image={
                                post?.attachments[0]?.attachmentType.toLowerCase() === 'video'
                                    ? Placeholder
                                    : post?.attachments[0]?.attachment
                            }
                            contactKey={post.id}
                        />
                    ),
                    texto: post.content,
                    Accion: <Actions postSelect={postSelect} postId={post.id} />,
                })
            })

            const sortedByDate = sortByDecreasingDate(auxData, 'date', 'DD/MM/YY')

            setData(sortedByDate)
        }
    }, [posts])

    function postSelect(type, id) {
        const selectedPost = posts.filter((post) => post.id === id)[0]
        if (selectedPost) setSelectedPost(selectedPost)
        setModalType(type)
    }

    return (
        <DashboardLayout>
            <div className="publications-wrapper">
                <ContainerHeader
                    title="Publicaciones"
                    button="Crear publicación "
                    onClick={() => postSelect('CREATE')}
                />
                {data?.length > 0 ? (
                    <Table data={data} columns={columns} />
                ) : (
                    <NoInfo text="No hay publicaciones disponibles" />
                )}
                <ModalPublicationsInfo
                    open={['CREATE', 'VIEW', 'EDIT'].includes(modalType)}
                    close={() => {
                        setModalType(null)
                        setSelectedPost(null)
                    }}
                    view={modalType === 'VIEW'}
                    edit={edit}
                    submit={submit}
                    loading={loading}
                    publication={selectedPost}
                />
                <ConfirmationModal
                    open={modalType === 'DELETE'}
                    close={() => {
                        setModalType(null)
                        setSelectedPost(null)
                    }}
                    confirmButton={() => {
                        remove(selectedPost.id)
                        setModalType(null)
                        setSelectedPost(null)
                    }}
                    denyButton={() => setModalType(null)}
                    confirmText="Eliminar"
                    icon={<HiOutlineTrash />}
                    title="¿Eliminar publicación?"
                    subtitle={`estás a punto de eliminar la publicación del día ${moment(selectedPost?.date).format(
                        'DD/MM/YY'
                    )}`}
                    loading={loading}
                />
            </div>
        </DashboardLayout>
    )
}

const Actions = (props) => {
    const { postSelect, postId } = props
    return (
        <div className="action-buttons-wrapper">
            <div
                onClick={() => {
                    postSelect('EDIT', postId)
                }}
            >
                <FiEdit3 />
            </div>
            <div
                onClick={() => {
                    postSelect('DELETE', postId)
                }}
            >
                <HiOutlineTrash />
            </div>
            <DetailButton
                onClick={() => {
                    postSelect('VIEW', postId)
                }}
            >
                Ver Detalles
            </DetailButton>
        </div>
    )
}

export default PublicationsContainer
