import { endpoints } from './endpoints'
import { getUserToken } from './utils'
import customFetch from './customFetch'

export const postCouponService = async (data) => {
    try {
        let response = await customFetch(`${endpoints.coupon}`, {
            method: 'POST',
            token: getUserToken(),
            bodyReq: data,
        })

        if (response.error) throw response

        return response
    } catch (err) {
        throw err
    }
}

export const removeCouponService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.coupon}/${id}`, {
            method: 'DELETE',
            token: getUserToken(),
        })

        if (response.error) throw response

        return response
    } catch (err) {
        throw err
    }
}

export const changeCouponService = async (data, id) => {
    try {
        let response = await customFetch(`${endpoints.coupon}/${id}`, {
            method: 'PATCH',
            token: getUserToken(),
            bodyReq: data,
        })

        if (response.error) throw response

        return response
    } catch (err) {
        throw err
    }
}

export const postPromotionCouponService = async (data) => {
    try {
        let response = await customFetch(`${endpoints.coupon}/promotion-code`, {
            method: 'POST',
            token: getUserToken(),
            bodyReq: data,
        })

        if (response.error) throw response

        return response
    } catch (err) {
        throw err
    }
}

export const changePromotionCouponService = async (data, id) => {
    try {
        let response = await customFetch(`${endpoints.coupon}/promotion-code/${id}`, {
            method: 'PATCH',
            token: getUserToken(),
            bodyReq: data,
        })

        if (response.error) throw response

        return response
    } catch (err) {
        throw err
    }
}

export const getCouponService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.coupon}/${id}`, {
            method: 'GET',
            token: getUserToken(),
        })

        if (response.error) throw response

        return response
    } catch (err) {
        throw err
    }
}

export const getPromotionCouponService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.coupon}/promotion-code/${id}`, {
            method: 'GET',
            token: getUserToken(),
        })

        if (response.error) throw response

        return response
    } catch (err) {
        throw err
    }
}

export const patchCouponService = async (bodyReq, id) => {
    try {
        let response = await customFetch(`${endpoints.coupon}/${id}`, {
            method: 'PATCH',
            token: getUserToken(),
            bodyReq,
        })

        if (response.error) throw response

        return response
    } catch (err) {
        throw err
    }
}
