import React, { useState } from 'react';
import './ContainerHeader.scss';
import { CgClose } from 'react-icons/cg';
import SecondaryButton from './SecondaryButton/SecondaryButton';

const ContainerHeader = (props) => {
    const { title, button, onClick, modal, secondaryButton } = props;

    return (
        <div
            className="title-wrapper"
            style={modal ? { borderBottom: '1px solid rgba(255, 255, 255, 0.12)' } : {}}
        >
            <h2 style={modal ? { fontSize: '17px' } : {}}>{title}</h2>
            {!modal ? (
                <div className="buttons-wrapper">
                    {secondaryButton && <SecondaryButton infos={secondaryButton}/>}
                    {button && <button
                        className="title-button-wrapper"
                        onClick={onClick}>
                        {button}
                    </button>}
                </div>
            ) : (
                <div onClick={onClick} className="close-icon-wrapper">
                    <CgClose />
                </div>
            )}
        </div>
    )
}

export default ContainerHeader;