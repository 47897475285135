import React, { useState } from "react";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import { Formik, Form } from "formik";
import * as yup from "yup";
import './NewPasswordComponents.scss';

const NewPasswordComponents = props => {
    const { error, submit, loading } = props;

    return (
        <AuthLayout>
            <div className="register-component">
                <h2>Crea tu contraseña</h2>
                <Formik
                    initialValues={{ password: "", confirmPassword: "" }}
                    validationSchema={yup.object().shape({
                        password: yup
                            .string()
                            .min(8, 'La contraseña debe tener al menos 8 caracteres')
                            .required("Campo obligatorio"),
                        confirmPassword: yup
                            .string()
                            .oneOf([yup.ref('password'), null], "Las contraseñas deben coincidir")
                            .required("Campo obligatorio"),
                    })}
                    onSubmit={(values) => {
                        submit(values);
                    }}>
                    {(formik) => (
                        <>  
                            <Form>
                                <Input
                                    id="password"
                                    formik={formik}
                                    type="password"
                                    label="Nueva contraseña"
                                    placeholder="Introduce tu nueva contraseña"
                                />
                                <Input
                                    id="confirmPassword"
                                    formik={formik}
                                    type="password"
                                    label="Repetir nueva contraseña"
                                    placeholder="Repite tu nueva contraseña"
                                />

                                {
                                    error ?
                                        <div className="error-message-global">{error}</div> :
                                        null
                                }

                                <Button
                                    loading={loading}
                                    type="button"
                                    onClick={() => formik.handleSubmit()}>
                                    Guardar contraseña
                                </Button>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </AuthLayout>
    );
}

export default NewPasswordComponents;
