import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getZonesService = async (userId) => {
    try {
        let response = await customFetch(`${endpoints.zones}?filter={"where":{"and":[{"idClub": "${userId}"}]}}`, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw e;
    }
}

export const getZoneService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.zones}/${id}`, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw e;
    }
}

export const postZoneService = async (values) => {
    try {
        let response = await customFetch(`${endpoints.zones}`, {
            method: 'POST',
            bodyReq: values,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw e;
    }
}

export const patchZoneService = async (values, id) => {
    try {
        
        let response = await customFetch(`${endpoints.zones}/${id}`, {
            method: 'PATCH',
            bodyReq: values,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw e;
    }
}

export const deleteZoneService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.zones}/${id}`, {
            method: 'DELETE',
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw e;
    }
}

export const postPalcoService = async (zoneId, values) => {
    try {
        let response = await customFetch(`${endpoints.zones}/${zoneId}/add-palco`, {
            method: 'POST',
            bodyReq: values,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (e) {
        throw e;
    }
}