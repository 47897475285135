import React, { useEffect, useState } from 'react';
import { getBookingService, getCheckinDetailsService } from 'services/bookingService';
import GeneralEntranceComponent from '../components/GeneralEntranceComponent';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";

const GeneralEntrance = (props) => {
    const { history, user } = props;
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState(false);

    useEffect(() => {
        getBooking();
    }, [])

    
    const getBooking = async () => {
        try {
            const res = await getBookingService(user.idClub);

            if (res) {
                const generalBookings = []
                res.forEach((booking) => {
                    if (booking.tickets[0]?.ticketInfo?.type === 'general') generalBookings.push(booking)
                })
                
                setBookings(generalBookings);
            }
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
    }

    return (
        <GeneralEntranceComponent
            {...props}
            {...{ history, loading, bookings, user }}
        />
    );
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralEntrance))