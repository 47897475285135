import React, { useState, useEffect } from 'react'
import EditZonesComponent from '../components/EditZonesComponent'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { updateUser } from 'redux/actions/userActions';
import { getZoneService, patchZoneService, postPalcoService, postZoneService } from 'services/zonesService';
import { deletePalcoService, patchPalcoService } from 'services/stageService';
import { toast } from 'react-toastify';
import ToastrContent from 'components/elements/Toastr/Toastr';
import _ from 'lodash';

const EditZonesContainer = (props) => {
    const { history, user } = props;
    const [loading, setLoading] = useState(false);
    const [zone, setZone] = useState();

    const { zoneId } = useParams();

    useEffect(() => {
        const editMode = window.location.pathname.includes('zone/');
        if (editMode) {
            getZone(zoneId);
        }
    }, [])

    function failToast() {
        return toast(<ToastrContent type='danger' title={'Error'} message={'Inténtalo más tarde'} />, {
            progressClassName: 'toastr-progress-bar danger',
        })
    }

    const getZone = async (id) => {
        try {
            const res = await getZoneService(id);
            setZone(res);
        }
        catch (e) {
            console.log('error setting preferences', e);
            throw (e)
        }
        finally {
        }
    }

    const create = async (values) => {
        try {
            setLoading(true);
            const data = {
                idClub: user.id,
                name: values.name,
                image: values.image.base64
            }
            const res = await postZoneService(data);

            if (res && values.palcos.length > 0) {
                Promise.all(values.palcos.map((palco) => createPalco(palco, res.zoneId)))
            }

            toast(<ToastrContent type="success" title={"Éxito"} message={"la zona ha sido creada."} />, {
                progressClassName: "toastr-progress-bar success",
            });
        }
        catch (e) {
            failToast()
            console.log('error setting preferences', e);
        }
        finally {
            setLoading(false);
            history.push(`/club/${user.id}/my-club/zones`);
        }
    }

    const createPalco = async (values, id) => {
        const data = {};

        try {
            setLoading(true);
            data.name = values.name_palco;
            data.price = parseInt(values.price_palco) || 0;
            data.capacity = parseInt(values.maxPeople_palco) || 0;
            const res = await postPalcoService(id || zoneId, data);

            return res;
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
            setLoading(false);
            history.push(`/club/${user.id}/my-club/zones`);
        }
    }

    const edit = async (value) => {
        try {
            setLoading(true);
            const palcosToDelete = [];

            if (zone?.palcos?.length) {
                zone.palcos.forEach((zonePalco) => {
                    let existPalco = false;
                    value.palcos.forEach((palco) => palco.id_palco === zonePalco.palcoId && !existPalco ? existPalco = true : null)

                    if (!existPalco) palcosToDelete.push(zonePalco)
                })
            }

            palcosToDelete.forEach((palco) => deletePalcoService(palco.palcoId));

            const newPalcos = []
            const old = value.palcos.filter((palco) => palco.id_palco !== "");
            const news = value.palcos.filter((palco) => palco.id_palco === "");

            Promise.all(news.map((palco) => createPalco(palco))).then((values) => {
                newPalcos.push(values);
            });

            const palcosToChange = [];

            value.palcos.forEach((palcoValue) => {
                if (zone?.palcos?.length) {
                    zone.palcos.forEach((palcoZone) => {
                        if (palcoValue.id_palco === palcoZone.palcoId &&
                            (Number(palcoValue.maxPeople_palco) !== Number(palcoZone.capacity) ||
                                palcoValue.name_palco !== palcoZone.name ||
                                Number(palcoValue.price_palco) !== Number(palcoZone.price))) {
                            palcosToChange.push(palcoValue);
                        }
                    })
                }
            })

            palcosToChange.forEach((palco) => {
                const palcoData = {
                    palcoId: palco.id_palco,
                    name: palco.name_palco,
                    price: parseInt(palco.price_palco),
                    capacity: parseInt(palco.maxPeople_palco),
                    zoneId: zone.zoneId,
                }
                patchPalcoService(palcoData, palco.id_palco);
            })

            const dataZone = {
                zoneId: value.ZoneId,
                idClub: user.id,
                name: value.name,
                image: value.image.base64
            }

            await patchZoneService(dataZone, zoneId);

            getZone(zoneId);

            toast(<ToastrContent type="success" title={"Éxito"} message={"La zona se ha cambiado."} />, {
                progressClassName: "toastr-progress-bar success",
            });
        }
        catch (e) {
            console.log('error setting preferences', e);
            failToast()
        }
        finally {
            setLoading(false);
            history.push(`/club/${user.id}/my-club/zones`);
        }
    }

    return (
        <EditZonesComponent
            {...props}
            {...{ history, user, loading, zone, create, createPalco, edit }}
        />
    )
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditZonesContainer))