import React, { useState, useEffect } from 'react'
import {
    getCouponService,
    getPromotionCouponService,
    postCouponService,
    postPromotionCouponService,
    removeCouponService,
} from 'services/couponService'
import {
    changePostSocialNetworkService,
    deletePostAttachment,
    deletePostSocialNetworkService,
    getUserPostsSocialNetworkService,
    writePostSocialNetworkService,
} from 'services/postSocialNetwork'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import ToastrContent from 'components/elements/Toastr/Toastr'
import PromotionsComponent from '../components/PromotionsComponent'
import moment from 'moment'

const Promotions = (props) => {
    const { user } = props
    const [loading, setLoading] = useState(false)
    const [promotions, setPromotions] = useState([])

    useEffect(() => {
        getPosts()
    }, [])

    const failRequest = ({ error }) => {
        let errorMessage
        if (error?.message?.message === 'This code already exist') errorMessage = 'Este código ya existe'
        return toast(
            <ToastrContent
                type="danger"
                title={'Error'}
                message={errorMessage || error?.message?.message || 'Inténtalo más tarde'}
            />,
            {
                progressClassName: 'toastr-progress-bar danger',
            }
        )
    }

    const getPosts = async () => {
        try {
            setLoading(true)
            const resp = await getUserPostsSocialNetworkService()

            const filteredDataByCoupon = resp.filter((publication) => publication.isPromotion)

            const couponsPromises = filteredDataByCoupon.map((publication) => getCoupons(publication.idCoupon))
            const promoCouponsPromises = filteredDataByCoupon.map((publication) => {
                return getPromotionCoupon(publication.idPromotion)
            })

            const coupons = await Promise.all(couponsPromises)
            const promoCoupons = await Promise.all(promoCouponsPromises)

            const filteredData = filteredDataByCoupon.map((promo) => {
                const selectedCoupon = coupons.filter((coupon) => coupon.id === promo.idCoupon)[0]
                const selectedPromoCoupon = promoCoupons.filter((coupon) => {
                    return coupon.id === promo.idPromotion
                })[0]

                return {
                    key: promo.id,
                    couponId: selectedCoupon.id,
                    couponName: selectedCoupon.name,
                    maxUsage: selectedCoupon.max_redemptions,
                    discount: selectedCoupon.percent_off,
                    promotionId: selectedPromoCoupon.id,
                    code: selectedPromoCoupon.code,
                    dateStart: moment(selectedCoupon.metadata.initialDate).format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                    dateEnd: moment(selectedCoupon.metadata.finalDate).format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                    image: {
                        attachment: promo?.attachments[0]?.attachment || '',
                        attachmentType: promo?.attachments[0]?.attachmentType || '',
                    },
                    text: promo.content,
                }
            })
            if (resp) setPromotions(filteredData)
        } catch (e) {
            console.log('error setting preferences', e)
        } finally {
            setLoading(false)
        }
    }

    const getCoupons = async (couponId) => {
        try {
            setLoading(true)
            const couponData = await getCouponService(couponId)

            return couponData
        } catch (e) {
            console.log('error updating', e)
        } finally {
            setLoading(false)
        }
    }

    const getPromotionCoupon = async (promoId) => {
        try {
            setLoading(true)
            const couponData = await getPromotionCouponService(promoId)

            return couponData
        } catch (e) {
            console.log('error updating', e)
        } finally {
            setLoading(false)
        }
    }

    const submit = async (values) => {
        try {
            setLoading(true)

            let couponData = {
                name: values.name,
                percent_off: values.discount,
                max_redemptions: values.maxUsage,
                currency: 'eur',
                metadata: {
                    initialDate: moment(values.dateStart).format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                    finalDate: moment(values.dateEnd).format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                    idClub: user.id,
                },
            }

            const respCoupon = await postCouponService(couponData) //todo don't have PATCH to change the data

            const promoData = {
                coupon: respCoupon.id,
                code: values.code,
            }

            const respPromoCoupon = await postPromotionCouponService(promoData) //create a promo coupon

            let postData = {
                content: values.description,
                publishedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                public: true,
                isClub: true,
                isPromotion: true,
                idCoupon: respCoupon.id,
                idPromotion: respPromoCoupon.id,
            }

            if (values?.file?.base64) {
                postData.attachments = [
                    {
                        attachment: values.file?.base64,
                        attachmentType: 'IMG',
                    },
                ]
            } else if (values.file?.id) {
                postData.attachments = [
                    {
                        attachment: values.file?.id,
                        attachmentType: 'VIDEO',
                    },
                ]
            }

            const res = await writePostSocialNetworkService(postData)

            toast(<ToastrContent type="success" title={'Éxito'} message={'La publicación ha sido creada'} />, {
                progressClassName: 'toastr-progress-bar success',
            })
            getPosts()
        } catch (e) {
            console.log('error setting preferences', e)
            failRequest(e)
        } finally {
            setLoading(false)
        }
    }

    const edit = async (values) => {
        const selectedData = promotions.filter((publication) => {
            return publication.key === values.key
        })[0]

        try {
            setLoading(true)
            let couponData = {
                name: values.name,
                percent_off: values.discount,
                max_redemptions: values.maxUsage,
                currency: 'eur',
                metadata: {
                    initialDate: moment(values.dateStart).format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                    finalDate: moment(values.dateEnd).format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                    idClub: user.id,
                },
            }

            let respCoupon
            let respPromoCoupon

            if (selectedData.code !== values.code) {
                respCoupon = await postCouponService(couponData) //todo don't have PATCH to change the data

                const promoData = {
                    coupon: respCoupon.id,
                    code: values.code,
                }

                respPromoCoupon = await postPromotionCouponService(promoData) //create a promo coupon
                const resCoupon = await removeCouponService(selectedData.couponId)
            }

            let data = {}
            if (values.file.base64 || values.file.attachmentType === 'IMG') {
                if (!values?.file?.path) {
                    data.attachments = [
                        {
                            attachment: values?.file?.base64,
                            attachmentType: 'IMG',
                        },
                    ]

                    const selectedPromo = promotions.filter((promo) => {
                        return promo.key === values.key
                    })[0]
                    const imageToDelete = selectedPromo.image.attachment
                    const deleteImage = await deletePostAttachment(encodeURIComponent(imageToDelete), selectedPromo.key)
                }
            }

            if (values.file.attachmentType === 'VIDEO' || (values.file.path && values.file.attachmentType !== 'IMG')) {
                if (!!values.file.id) {
                    data.attachments = [
                        {
                            attachment: values.file.id,
                            attachmentType: 'VIDEO',
                        },
                    ]
                    const selectedPromo = promotions.filter((promo) => {
                        return promo.key === values.key
                    })[0]
                    const imageToDelete =
                        selectedPromo.image.attachmentType === 'IMG' ? selectedPromo.image.attachment : null
                    imageToDelete && (await deletePostAttachment(encodeURIComponent(imageToDelete), selectedPromo.key))
                }
            }

            let postData = {
                ...data,
                content: values.description,
                publishedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                public: true,
                isClub: true,
                isPromotion: true,
                idCoupon: respCoupon?.id,
                idPromotion: respPromoCoupon?.id,
            }

            const res = await changePostSocialNetworkService(postData, values.key)

            toast(<ToastrContent type="success" title={'Éxito'} message={'La publicación ha sido actualizada'} />, {
                progressClassName: 'toastr-progress-bar success',
            })

            getPosts()
        } catch (e) {
            console.log('error updating', e)
            failRequest(e)
        } finally {
            setLoading(false)
        }
    }

    const remove = async (postId) => {
        try {
            setLoading(true)
            const promoToRemove = promotions.filter((promo) => promo.id === postId)[0]

            const resCoupon = await removeCouponService(promoToRemove.couponId)
            const resPost = await deletePostSocialNetworkService(promoToRemove.key)

            toast(<ToastrContent type="success" title={'Éxito'} message={'La promoción ha sido eliminada'} />, {
                progressClassName: 'toastr-progress-bar success',
            })
            getPosts()
        } catch (e) {
            console.log('error updating', e)
            toast(<ToastrContent type="danger" title={'Error'} message={'Inténtalo más tarde'} />, {
                progressClassName: 'toastr-progress-bar danger',
            })
        } finally {
            setLoading(false)
        }
    }

    return <PromotionsComponent {...props} {...{ submit, promotions, edit, remove }} />
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
})

export default withRouter(connect(mapStateToProps)(Promotions))
