import React, { useState, useEffect } from 'react'
import Button from 'components/elements/Button/Button'
import ContainerHeader from 'components/elements/ContainerHeader/ContainerHeader'
import Input from 'components/elements/Input/Input'
import ReactModal from 'components/elements/ReactModal/ReactModal'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import './ModalNotifications.scss'

const ModalNotifications = (props) => {
    const { openModal, handleModal, viewMode, loading, submit, notification } = props

    const formik = useFormik({
        initialValues: {
            subject: notification?.title || '',
            description: notification?.description || '',
            date: notification?.date || '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            // key: Yup.string().required("Obligatorio"),
            date: Yup.string(),
            subject: Yup.string().required('Obligatorio'),
            description: Yup.string().max(250, 'máximo de 250 caracteres').required('Obligatorio'),
        }),
        onSubmit: (values) => {
            submit(values)
            formik.resetForm()
        },
    })

    return (
        <ReactModal
            open={openModal}
            close={() => {
                handleModal()
                formik.resetForm()
            }}
            header={
                <ContainerHeader
                    title={viewMode ? 'Notificación' : 'Notificaciones'}
                    onClick={() => {
                        handleModal()
                        formik.resetForm()
                    }}
                    modal
                />
            }
            footer={<FooterModal viewMode={viewMode} formik={formik} loading={loading} />}
        >
            <div className="modal-wrapper">
                <div className="modal-components" style={{ paddingBottom: '20px' }}>
                    <Input
                        id="subject"
                        formik={formik}
                        type="text"
                        label="Asunto"
                        placeholder="Asunto"
                        disabled={viewMode}
                    />
                </div>
                <div className="modal-components">
                    <Input
                        id="description"
                        formik={formik}
                        type={viewMode ? 'text' : 'textarea'}
                        label="Descripción"
                        placeholder="Introduce una descripción para la notificaciones"
                        disabled={viewMode}
                    />
                </div>
            </div>
        </ReactModal>
    )
}
export default ModalNotifications

const FooterModal = (props) => {
    const { viewMode, formik, loading } = props
    return viewMode ? (
        <div className="modal-components" style={{ paddingBottom: '15px' }}>
            <Input id="date" formik={formik} type="text" label="Enviada el" disabled={viewMode} />
        </div>
    ) : (
        <div className="modal-components">
            <Button loading={loading} type="button" onClick={() => formik.handleSubmit()}>
                Enviar notificación
            </Button>
        </div>
    )
}
